import {computed, inject, Injectable, signal} from "@angular/core";
import {ListResponse} from "../../../types";
import {PinboardTag, PinboardTagListFilter} from "../types";
import {HttpParams} from "@angular/common/http";
import {ApiService} from "../../../services/api.service";

@Injectable({providedIn: "root"})
export class PinboardTagService {
  private api = inject(ApiService);
  public filter = signal<PinboardTagListFilter>({
    search: '',
    clientId: '',
    projectId: '',
    page: 0,
    size: 50,
    orderBy: 'created',
    direction: 'desc'
  });

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }

  filterSetProjectId(projectId: string) {
    this.filter.update(value => ({...value, projectId}));
  }

  private listResponse = signal<ListResponse<PinboardTag>>({
    items: [],
    total: 0
  });
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);
  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      ;
  }

  async loadList() {
    const response = await this.api.fetchList<PinboardTag>('pinboardtag', this.listParams);
    this.listResponse.set(response);
    return response;
  }

  async save(item: PinboardTag) {
    return this.api.save<PinboardTag>('pinboardtag', item, true);
  }
}
