import {ChangeDetectionStrategy, Component, HostBinding, inject, input, OnInit, output} from "@angular/core";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {LatLng} from "leaflet";
import {NotificationService} from "../../../services/notification.service";
import {MapEntry, mapEntryFactory, MapTag} from "../types";
import {MapEntryService} from "../services/map-entry.service";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {AuthService} from "../../auth/services/auth.service";

@Component({
  selector: 'rich-map-comment-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatOption,
    MatSelect,
    MatButton
  ],
  template: `
    <h3>Beitrag schreiben</h3>
    <div class="flex-row fields-container flex-wrap">
      <div class="field-container flex">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="nameControl"/>
          <mat-error>Name benötigt!</mat-error>
        </mat-form-field>
      </div>
      <div class="field-container flex">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailControl"/>
          <mat-error>Valide Email-Adresse benötigt!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="flex-row fields-container flex-wrap">
      <div class="field-container flex">
        <mat-form-field appearance="outline">
          <mat-label>Titel</mat-label>
          <input matInput [formControl]="titleControl"/>
          <mat-error>Titel benötigt!</mat-error>
        </mat-form-field>
      </div>
      <div class="field-container">
        <mat-form-field appearance="outline">
          <mat-label>TAG</mat-label>
          <mat-select [formControl]="tagControl">
            @for (tag of tags(); track tag) {
              <mat-option [value]="tag.id">{{tag.name}}</mat-option>
            }
          </mat-select>
          <mat-error>TAG notwendig!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-column fields-container">
      <div class="flex-1 field-container comment">
        <mat-form-field appearance="outline">
          <mat-label>Beitrag</mat-label>
          <textarea matInput [formControl]="commentControl"></textarea>
          <mat-error>Kommentar kann nicht leer sein!!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="button-container">
      <button (click)="cancel()" mat-button>Abbrechen</button>
      <button (click)="save()" mat-raised-button>Absenden</button>
    </div>
  `
})
export class RichMapCommentFormComponent implements OnInit {
  @HostBinding('class.form-container') classRichFormContainer = true;
  @HostBinding('class.rich-map-comment-form') classRichMapCommentForm = true;
  private note = inject(NotificationService);
  private mapEntryService = inject(MapEntryService);
  private auth = inject(AuthService);
  clientId = input('');
  projectId = input('');
  isUserActivated = input(false);
  latLng = input<LatLng>({ lat: 0, lng: 0} as  L.LatLng);
  tags = input<Array<MapTag>>([]);
  saved = output<MapEntry>();
  canceled = output<void>();
  formBuilder = inject(FormBuilder);
  nameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  titleControl = new FormControl('', [Validators.required]);
  tagControl = new FormControl('', [Validators.required]);
  commentControl = new FormControl('', [Validators.required]);

  form = this.formBuilder.group({
    name: this.nameControl,
    email: this.emailControl,
    title: this.titleControl,
    tag: this.tagControl,
    comment: this.commentControl,
  });

  save() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      console.log('form invalid', this.form.errors);
      return;
    }

    const newComment: MapEntry = {
      ...mapEntryFactory(),
      author_name: `${this.nameControl.value}`,
      author_email: `${this.emailControl.value}`,
      title: `${this.titleControl.value}`,
      short_description: `${this.commentControl.value}`,
      config: JSON.stringify({latlng: this.latLng()}),
      moderation_status: this.isUserActivated() ? 'approved' : 'pending',
      client_id: this.clientId(),
      project_id: this.projectId(),
      tag_ids: [`${this.tagControl.value}`],
    };

    console.log('save comment', newComment);

    this.mapEntryService.save(newComment)
      .then(this.success)
      .catch(this.fail);
  }

  success = (item: MapEntry) => {
    this.note.note('Kommentar gespeichert!');
    this.reset();
    this.saved.emit(item);
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  reset() {
    this.form.reset();

    if(this.isUserActivated()) {
      this.nameControl.setValue(this.auth.userName(), {emitEvent: false});
      this.nameControl.disable();
      this.emailControl.setValue(this.auth.userEmail(), {emitEvent: false});
      this.emailControl.disable();
    }
  }

  cancel() {
    this.reset();
    this.canceled.emit();
  }

  ngOnInit() {
    this.reset();
  }
}
