import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Client, clientFactory} from "./index";
import {ListResponse, ListFilter} from "../../types";
import {CanActivateFn} from "@angular/router";
import {ApiService} from "../../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private http = inject(HttpClient);
  private api = inject(ApiService);
  public currentItem = signal(clientFactory());

  public filter = signal<ListFilter>({
    search: '',
    page: 0,
    size: 50,
    orderBy: 'name',
    direction: 'asc'
  });

  private listResponse: WritableSignal<ListResponse<Client>> = signal<ListResponse<Client>>({
    items: [],
    total: 0
  });
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`);
  }

  async fetchList() {
    const response = this.api.fetchList<Client>('client', this.listParams);
    response.then(value => this.listResponse.update(state => value as ListResponse<Client>));
    response.catch(error => console.log('ClientService::fetchList()', error));
    return response;
  }

  defaultClient = computed(() => this.items().find(client => client.name === 'default') || clientFactory());

  async fetchById(id: string): Promise<Client> {
    return this.api.fetchById<Client>(`client`, id);
  }

  async save(item: Client): Promise<Client> {
    return this.api.save<Client>('client', item, true);
  }

  async deleteById(id: string){
    return this.api.deleteById<Client>('client', id, true);
  }

  // getDefaultClient(): Client {
  //   const defaultClient = this.items().find(client => client.name === 'default');
  //   return defaultClient || clientFactory();
  // }
}

export const canEditClient: CanActivateFn = async (route, state) => {
  const service = inject(ClientService);
  try {
    const client = await service.fetchById(`${route.paramMap.get('id')}`);
    service.currentItem.set(client);
  } catch {
    service.currentItem.set(clientFactory());
  }
  return true;
};

export const canCreateClient: CanActivateFn = (route, state) => {
  inject(ClientService).currentItem.set(clientFactory());
  return true;
};
