import {ChangeDetectionStrategy, Component, computed, input} from "@angular/core";
import {pinboardEntryCommentFactory} from "../types";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatDivider} from "@angular/material/divider";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'pinboard-entry-comment-display',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatDivider,
    MarkdownComponent
  ],
  template: `
    <mat-card>
      <mat-card-header>
        Von: {{author()}}<br />
        {{created()}}
      </mat-card-header>
      <mat-divider />
      <mat-card-content>
        <markdown [data]="content()" />
      </mat-card-content>
    </mat-card>
<!--    <div class="flex-row gap">-->
<!--      <div>Autor: {{author()}}</div>-->
<!--      <div>{{created()}}</div>-->
<!--    </div>-->
<!--    <div class="comment-content">{{content()}}</div>-->

<!--    <pre>{{comment() | json}}</pre>-->
  `
})
export class PinboardEntryCommentDisplayComponent {
  comment = input(pinboardEntryCommentFactory());
  author = computed(() => this.comment().author_name);
  created = computed(() => this.comment().created);
  content = computed(() => this.comment().content);
}
