import {ChangeDetectionStrategy, Component, computed, HostBinding, input} from "@angular/core";
import {ComponentConfig} from "../../../project";
import {ContainerColumnConfig} from "../select-component-dialog/types";
import {DeployComponentContainerColumnComponent} from "./deploy-component-container-column.component";

@Component({
  selector: 'deploy-component-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DeployComponentContainerColumnComponent,
  ],
  template: `
    <div class="component-container-layout" [style.grid-template-columns]="gridTemplateColumns()">
      @for (column of columns(); track column) {
        <deploy-component-container-column [column]="column"
                                           [clientId]="clientId()"
                                           [projectId]="projectId()" />
      }
    </div>
  `
})
export class DeployComponentContainerComponent {
  @HostBinding('class.deploy-component-container') cssClass = true;
  @HostBinding('class.component-container') containerClass = true;
  component = input.required<ComponentConfig>();
  clientId = input.required<string>();
  projectId = input.required<string>();
  config = computed(() => this.component().config);
  columns = computed<Array<ContainerColumnConfig>>(() => this.config()['columns']);
  gridTemplateColumns = computed<string>(() => this.columns().map(column => column.gridColumnWidth).join(' '));
}
