import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {DynamicSiteHeaderComponent} from "../../navigation/dynamic-site-header.component";
import {MapEntryService} from "../services/map-entry.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {DeployFooterComponent} from "../../../deploy/deploy-footer.component";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {MapEntryDisplayComponent} from "./map-entry-display.component";
import {MapEntryCommentService} from "../services/map-entry-comment.service";
import {MapEntryCommentDisplayComponent} from "./map-entry-comment-display.component";
import {MapEntryCommentFormComponent} from "./map-entry-comment-form.component";

@Component({
  selector: 'map-entry-display-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicSiteHeaderComponent,
    MatButton,
    MatIcon,
    DeployFooterComponent,
    MapEntryDisplayComponent,
    MapEntryCommentDisplayComponent,
    MapEntryCommentFormComponent,
  ],
  template: `
    <dynamic-site-header />
    <div class="page-content-container">
      <div class="toolbar">
        <div class="button-container">
          <button (click)="back()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            Zurück
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-container">
      <map-entry-display [entry]="entry()" />
    </div>

    <div class="page-content-container">
      <map-entry-comment-form [entry]="entry()"
                              (saved)="afterSave()" />
    </div>

    <div class="page-content-container flex-column gap"
         style="padding-top: 2rem;">
      @for (comment of comments(); track comment) {
        <map-entry-comment-display [comment]="comment" />
      }
    </div>

    <deploy-footer />
  `,
})
export class MapEntryDisplayPageComponent implements OnInit {
  @HostBinding('class.map-entry-display-page') classAdminPage = true;
  private pageEditorService = inject(PageEditorService);
  private mapEntryService = inject(MapEntryService);
  private commentService = inject(MapEntryCommentService);
  entry = this.mapEntryService.currentItem;
  comments = this.commentService.items;

  back() {
    this.pageEditorService.navigateToInitializedProject();
  }

  afterSave() {
    this.commentService.loadList();
  }

  ngOnInit() {
    this.commentService.filterSetModerationStatus('public');
    this.commentService.filterSetMapEntryId(this.entry().id);
    this.commentService.filterSetClientId(this.entry().client_id);
    this.commentService.filterSetProjectId(this.entry().project_id);
    this.commentService.loadList();
  }
}
