import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../services/notification.service";
import {MapEntryService} from "../services/map-entry.service";
import {MapTagService} from "../services/map-tag.servie";
import {MapEntry} from "../types";
import {AdminService} from "../../../admin/admin.service";

@Component({
  selector: 'admin-map-entry-detail-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatError,
  ],
  template: `
    <div class="form-row">
      <mat-form-field  appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="moderationStatusControl">
          <mat-option value="pending">Wartend</mat-option>
          <mat-option value="approved">Freigegeben</mat-option>
          <mat-option value="rejected">Abgelehnt</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline" class="flex">
        <mat-label>Name</mat-label>
        <input [formControl]="authorFirstNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>

    <div class="flex-row gap">

      <mat-form-field appearance="outline" class="flex">
        <mat-label>Titel</mat-label>
        <input [formControl]="requestTitleControl" matInput />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>TAG</mat-label>
        <mat-select [formControl]="tagControl">
          @for (tag of tags(); track tag) {
            <mat-option [value]="tag.id">{{tag.name}}</mat-option>
          }
        </mat-select>
        <mat-error>TAG notwendig!</mat-error>
      </mat-form-field>

    </div>



    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Text</mat-label>
        <textarea [formControl]="requestDescriptionControl" rows="6" matInput></textarea>
      </mat-form-field>
    </div>

    <div class="button-container">
      <button (click)="save()" mat-raised-button>Speichern</button>
      @if(!isNew()) {
        <button (click)="delete()" mat-raised-button>Löschen</button>
      }
    </div>
  `
})
export class AdminMapEntryDetailFormComponent implements OnInit {
  @HostBinding('class.admin-map-entry-detail-form') dssClass = true;
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private service = inject(MapEntryService);
  private adminService = inject(AdminService);
  link = this.nav.getSegments;
  item = this.service.currentItem;
  isNew = computed(() => this.item().id.length < 1);

  private pinboardTagService = inject(MapTagService);
  tags = this.pinboardTagService.items;

  authorFirstNameControl = new FormControl('');
  authorEmailControl = new FormControl('');
  requestTitleControl = new FormControl('');
  requestDescriptionControl = new FormControl('');
  moderationStatusControl = new FormControl('pending');
  tagControl = new FormControl('', [Validators.required]);

  save() {
    let mValue = `${this.moderationStatusControl.value}`;
    if(!['pending','rejected','approved'].includes(mValue)) {
      mValue = 'pending'
    }
    const newItem: MapEntry = {
      ...this.item(),
      client_id: this.adminService.clientId(),
      tag_ids: [`${this.tagControl.value}`],
      author_name: this.authorFirstNameControl.value || '',
      author_email: this.authorEmailControl.value || '',
      title: this.requestTitleControl.value || '',
      short_description: this.requestDescriptionControl.value || '',
      moderation_status: (mValue) as 'pending' | 'rejected' | 'approved',
    };

    console.log('save item', newItem);

    this.service.save(newItem)
      .then(this.success)
      .catch(this.fail);
  }

  success = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate('admin/map-entries');
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  delete = () => {
    this.note.confirm("Eintrag Löschen?", () => {
      this.service.deleteById(this.item().id)
        .then(this.deleteSuccess)
        .catch(this.deleteFail);
    });
  }

  deleteSuccess = () => {
    this.note.note('Gelöscht!');
    this.nav.navigate('admin/map-entries');
  }

  deleteFail = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }

  ngOnInit() {
    const item = this.item();
    console.log('init item MapEntry', item);
    this.authorFirstNameControl.setValue(item.author_name, {emitEvent: false});
    this.authorEmailControl.setValue(item.author_email, {emitEvent: false});
    this.requestTitleControl.setValue(item.title, {emitEvent: false});
    this.requestDescriptionControl.setValue(item.short_description, {emitEvent: false});
    this.moderationStatusControl.setValue(item.moderation_status, {emitEvent: false});
    this.tagControl.setValue(item.tag_ids[0], {emitEvent: false});
  }
}
