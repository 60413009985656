import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MapEntryDisplayComponent} from "./map-entry-display.component";
import {DeployService} from "../../../deploy/deploy.service";
import {NavigationService} from "../../../services/navigation.service";
import {RouterLink} from "@angular/router";
import {MapEntryCommentService} from "../services/map-entry-comment.service";
import {MapEntryCommentFormComponent} from "./map-entry-comment-form.component";
import {MapEntryCommentDisplayComponent} from "./map-entry-comment-display.component";

@Component({
  selector: 'map-entry-display-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatIcon,
    RouterLink,
    MapEntryDisplayComponent,
    MapEntryCommentFormComponent,
    MapEntryCommentDisplayComponent,
  ],
  template: `
    <div class="page-content-container">
      <div class="toolbar">
        <div class="button-container">
          <button [routerLink]="backLink()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            Zurück
          </button>
        </div>
      </div>
    </div>

    <div class="page-content-container">
      <map-entry-display [entry]="entry()" />
    </div>

    <div class="page-content-container">
      <map-entry-comment-form [entry]="entry()"
                              (saved)="afterSave()" />
    </div>

    <div class="page-content-container flex-column gap"
         style="padding-top: 2rem;">
      @for (comment of comments(); track comment) {
        <map-entry-comment-display [comment]="comment" />
      }
    </div>
  `,
})
export class MapEntryDeployDisplayPageComponent implements OnInit {
  @HostBinding('class.map-entry-display-page') classAdminPage = true;
  private deploy = inject(DeployService);
  private nav = inject(NavigationService);
  private mapEntryService = inject(MapEntryService);
  private commentService = inject(MapEntryCommentService);
  entry = this.mapEntryService.currentItem;
  backLink = computed(() => this.nav.getSegments(this.deploy.pagePath())());
  comments = this.commentService.items;

  afterSave() {
    this.commentService.loadList();
  }

  ngOnInit() {
    this.commentService.filterSetModerationStatus('public');
    this.commentService.filterSetMapEntryId(this.entry().id);
    this.commentService.filterSetClientId(this.entry().client_id);
    this.commentService.filterSetProjectId(this.entry().project_id);
    this.commentService.loadList();
  }
}
