import {ChangeDetectionStrategy, Component, HostBinding, inject} from '@angular/core';
import {AdminUserFormComponent} from "./admin-user-form.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {UserService} from "../services/user.service";
import {NavigationService} from "../../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-user-detail-page',
  standalone: true,
  imports: [
    AdminUserFormComponent,
    AdminPageHeaderComponent,
    MatIconModule,
    MatButtonModule,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header [title]="title" />

    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link$('admin/users')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="form-container">
      <app-user-form/>
    </div>
  `
})
export class AdminUserDetailPageComponent {
  @HostBinding('class.admin-page') classAdminPage = true;
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;

  service = inject(UserService);
  item$ = this.service.currentItem;

  get title(): string {
    return this.item$().id.length > 0
      ? 'Nutzer bearbeiten' : 'Nutzer erstellen'
  }
}
