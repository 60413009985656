import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";
import {MapTagService} from "../services/map-tag.servie";
import {mapEntryFactory} from "../types";
import {AdminService} from "../../../admin/admin.service";

export const canEditMapEntry: CanActivateFn =  async (route, state) => {
  const entryService = inject(MapEntryService);
  const tagService = inject(MapTagService);
  const adminService = inject(AdminService);
  tagService.filterSetClientId(adminService.clientId());

  try {
    const entryId = `${route.paramMap.get('id')}`;
    const entry = await entryService.fetchItem(entryId);
    if (!entry) {
      entryService.currentItem.set(mapEntryFactory());
      return true;
    }
    tagService.filter.update(filter => ({...filter, projectId: entry.project_id}));

    await tagService.loadList();
    entryService.currentItem.set(entry);
  } catch (e) {
    entryService.currentItem.set(mapEntryFactory());
  }
  return true;
}
