import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, signal} from "@angular/core";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {RouterLink} from "@angular/router";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {NotificationService} from "../../../services/notification.service";
import {NavigationService} from "../../../services/navigation.service";
import {confirmValidator, passwordValidator} from "../../../functions";
import {AuthPasswordResetService} from "../services/auth-password-reset.service";

@Component({
  selector: 'auth-password-reset-confirm-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    RouterLink,
    ReactiveFormsModule,
    MatButton,
  ],
  template: `
    <img src="/img/logo.svg" alt="Logo: Polit@ktiv" height="120" width="234"/>
    <div class="auth-process-content">

      @if(checkSuccessful()) {
        @if (processExecuted()) {

          <p><!-- confirm message -->
            Passwort erfolgreich zurückgesetzt.<br/>
            Sie können sich nun einloggen.
          </p>

        } @else {
          <div class="form-container">
            <div class="form-title">Passwort zurücksetzen</div>
            <div class="form-row">
              <div class="flex-1 field-container">
                <mat-form-field appearance="outline">
                  <mat-label>Passwort</mat-label>
                  <input matInput [formControl]="passwordControl" type="password"/>
                  <mat-error>Passwort invalide!</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-1 field-container">
                <mat-form-field appearance="outline">
                  <mat-label>Passwort Bestätigung</mat-label>
                  <input matInput [formControl]="passwordConfirmControl" type="password"/>
                  <mat-error>Bestätigung stimmt nicht überein!</mat-error>
                </mat-form-field>
              </div>
            </div>

            <p>
              Passwort benötigt 6 Zeichen. Davon min 1 Buchstabe,<br />
              min 1 Zahl, min 1 Sonderzeichen.
            </p>

            <div class="form-action-container">
              <button (click)="submit()" mat-raised-button>Passwort zurücksetzen</button>
            </div>
          </div>
        }

      } @else {
        <p>Anfrage invalide oder abgelaufen!</p>
      }


      <div style="padding-top: 1rem;"></div>
      <a [routerLink]="loginLink()">Zum Login</a>
    </div>
  `
})
export class AuthPasswordResetConfirmPageComponent {
  @HostBinding('class.auth-register-confirm-page') cssClass = true;
  @HostBinding('class.auth-process-page') cssTypeClass = true;
  private service = inject(AuthPasswordResetService);
  private note = inject(NotificationService);
  private nav = inject(NavigationService);
  private formBuilder = inject(FormBuilder);
  loginLink = this.nav.getSegments('auth');
  checkSuccessful = computed(() => this.service.checkResponse().success);
  processExecuted = signal(false);
  passwordControl = new FormControl('', [Validators.required, passwordValidator]);
  passwordConfirmControl = new FormControl('', [Validators.required, confirmValidator(this.passwordControl)]);
  private form = this.formBuilder.group({
    password: this.passwordControl,
    passwordConfirm: this.passwordConfirmControl,
  });

  submit() {
    this.form.markAllAsTouched();
    if(!this.form.valid) {
      return;
    }

    this.service.passwordResetFinalize({
      reset_key: this.service.resetKey(),
      password: `${this.passwordControl.value}`,
    }).then(this.success).catch(this.fail);
  }

  private success = () => {
    this.note.note('Passwort erfolgreich zurück gesetzt!');
    this.processExecuted.set(true);
  }

  private fail = () => {
    this.note.note('Passwort zurücksetzen fehlgeschlagen!');
  }
}
