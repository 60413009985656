import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {AdminPageHeaderComponent} from "../../../../admin/admin-page-header.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PageEditorService} from "../../services/page-editor.service";
import {PageEditorComponentContainerFormComponent} from "./page-editor-component-container-form.component";

@Component({
  selector: 'component-container-editor-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    MatButton,
    MatIcon,
    PageEditorComponentContainerFormComponent,
  ],
  template: `
    <admin-page-header title="Container bearbeiten" />
    <div class="toolbar">
      <div class="button-container">
        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
      </div>
    </div>

    <div class="form-container">
      <component-container-form />
    </div>
  `
})
export class PageEditorComponentContainerEditorPageComponent {
  @HostBinding('class.component-container-editor-page') cssClass = true;
  @HostBinding('class.admin-page') cssAdminPage = true;
  private pageEditorService = inject(PageEditorService);
  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }
}
