import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, Input, OnInit} from "@angular/core";
import {PinboardEntry} from "../types";
import {MatButton} from "@angular/material/button";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardTagService} from "../services/pinboard-tag.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../services/notification.service";
import {AdminService} from "../../../admin/admin.service";
import {ModerationStatus} from "../../../types";

@Component({
  selector: 'admin-pinboard-entry-detail-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect
  ],
  template: `
    <div class="form-row">
      <mat-form-field  appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="moderationStatusControl">
          <mat-option value="pending">Wartend</mat-option>
          <mat-option value="approved">Freigegeben</mat-option>
          <mat-option value="rejected">Abgelehnt</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input [formControl]="authorNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Überschrift</mat-label>
        <input [formControl]="titleControl" matInput />
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Kurzbeschreibung</mat-label>
        <textarea [formControl]="shortDescriptionControl" rows="2" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Artikel-Text</mat-label>
        <textarea [formControl]="contentControl" rows="6" matInput></textarea>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-select [formControl]="tagsControl" multiple>
        @for (tag of tags(); track tag) {
          <mat-option [value]="tag.id">{{tag.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="button-container">
      <button (click)="save()" mat-raised-button>Speichern</button>
      @if(!isNew()) {
        <button (click)="delete()" mat-raised-button>Löschen</button>
      }
    </div>
  `
})
export class AdminPinboardEntryDetailFormComponent implements OnInit {
  @HostBinding('class.admin-pinboard-entry-detail-form') cssClass = true;
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private service = inject(PinboardEntryService);
  link = this.nav.getSegments;
  item = this.service.currentItem;
  isNew = computed(() => this.item().id.length < 1);

  private pinboardTagService = inject(PinboardTagService);
  private adminService = inject(AdminService);
  tags = this.pinboardTagService.items;

  authorNameControl = new FormControl('');
  authorEmailControl = new FormControl('');
  titleControl = new FormControl('');
  shortDescriptionControl = new FormControl('');
  contentControl = new FormControl('');
  tagsControl = new FormControl<Array<string>>([]);
  moderationStatusControl = new FormControl('pending');

  save() {
    let mValue = `${this.moderationStatusControl.value}`;
    if(!['pending','rejected','approved'].includes(mValue)) {
      mValue = 'pending'
    }
    const newItem: PinboardEntry = {
      ...this.item(),
      client_id: this.item().client_id.length > 0 ? this.item().client_id : this.adminService.clientId(),
      tag_ids: this.tagsControl.value || [],
      author_name: this.authorNameControl.value || '',
      author_email: this.authorEmailControl.value || '',
      title: this.titleControl.value || '',
      short_description: this.shortDescriptionControl.value || '',
      content: this.contentControl.value || '',
      moderation_status: (mValue) as ModerationStatus,
    };

    // console.log('save item', newItem);

    this.service.save(newItem)
      .then(this.success)
      .catch(this.fail);
  }

  success = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate('admin/pinboard');
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  delete = () => {
    this.note.confirm("Eintrag Löschen?", () => {
      this.service.deleteById(this.item().id)
        .then(this.deleteSuccess)
        .catch(this.deleteFail);
    });
  }

  deleteSuccess = () => {
    this.note.note('Gelöscht!');
    this.nav.navigate('admin/pinboard');
  }

  deleteFail = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }

  ngOnInit() {
    const item = this.item();
    // console.log('init item', item);
    this.authorNameControl.setValue(item.author_name, {emitEvent: false});
    this.authorEmailControl.setValue(item.author_email, {emitEvent: false});
    this.titleControl.setValue(item.title, {emitEvent: false});
    this.shortDescriptionControl.setValue(item.short_description, {emitEvent: false});
    this.contentControl.setValue(item.content, {emitEvent: false});
    this.moderationStatusControl.setValue(item.moderation_status, {emitEvent: false});
    this.tagsControl.setValue(item.tag_ids, {emitEvent: false});
  }
}
