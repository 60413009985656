import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {ProjectService} from "../../project/services/project.service";
import {PageEditorService} from "../services/page-editor.service";

export const canEditProjectPages: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const projectService = inject(ProjectService);
  const projectId = `${route.paramMap.get('project_id')}`;
  const pageId = `${route.paramMap.get('page_id')}`;

  if (projectId.length < 1 || pageId.length < 1) {
    console.log('failing reading project_id or page_id');
    return false;
  }

  try {
    if (pageEditorService.project().id !== projectId) {

      const project = await projectService.fetchItem(projectId);
      pageEditorService.initProject(project);
    }

    if (pageEditorService.page().id === pageId) {
      return true; // correct page selected
    }

    const pages = pageEditorService.config().pages;
    // pages.forEach(page => console.log('page', page, page.id === pageId));
    if (!pages.some(page => page.id === pageId)) {
      return false; // invalid link
    }

    // pageEditorService.page.set(pages.find(page => page.id === pageId)); // editor cant figure it out...
    const page = pages.find(page => page.id === pageId);
    if (page !== undefined) {
      pageEditorService.page.set(page);
    }

  } catch (error) {
    console.log('Error', error);
    return false;
  }

  return true;
};
