import {computed, inject, Injectable, signal} from "@angular/core";
import {
  PinboardEntry,
  pinboardEntryFactory,
  pinboardEntryListFilterFactory,
} from "../types";
import {listResponseFactory, ModerationStatusFilter} from "../../../types";
import {HttpParams} from "@angular/common/http";
import {CanActivateFn} from "@angular/router";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {PinboardTagService} from "./pinboard-tag.service";
import {ApiService} from "../../../services/api.service";
import {AdminService} from "../../../admin/admin.service";

@Injectable({providedIn: "root"})
export class PinboardEntryService {
  private api = inject(ApiService);
  currentItem = signal(pinboardEntryFactory());
  filter = signal(pinboardEntryListFilterFactory());
  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }
  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }
  filterSetProjectId(projectId: string) {
    this.filter.update(value => ({...value, projectId}));
  }
  filterSetModerationStatus(moderationStatus: ModerationStatusFilter) {
    this.filter.update(value => ({...value, moderationStatus}));
  }
  filterSetTagIds(tagIds: Array<string>) {
    this.filter.update(value => ({...value, tagIds}));
  }

  private listResponse = signal(listResponseFactory<PinboardEntry>());

  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('moderation_status', `${this.filter().moderationStatus}`)
      .set('tag_ids', `${this.filter().tagIds.join(',')}`)
    ;
  }

  async loadList() {
    const response = await this.api.fetchList<PinboardEntry>('pinboardentry', this.listParams, true);
    this.listResponse.set(response);
    return response;
  }

  async fetchItem(id: string){
    return this.api.fetchById<PinboardEntry>('pinboardentry', id);
  }

  async save(item: PinboardEntry): Promise<PinboardEntry> {
    return this.api.save<PinboardEntry>('pinboardentry', item, true);
  }

  async deleteById(id: string){
    return this.api.deleteById<PinboardEntry>('pinboardentry', id, true);
  }
}

export const canEditPinboard: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const tagService = inject(PinboardTagService);
  const adminService = inject(AdminService);
  const componentId = `${route.paramMap.get('component_id')}`;

  const component = pageEditorService.findComponentById(componentId);

  if(component !== undefined) {
    pageEditorService.component.set(component);
    tagService.filterSetClientId(adminService.clientId());
    tagService.filterSetProjectId(pageEditorService.projectId());
    await tagService.loadList();
    return true;
  } else {
    console.log('component id not found');
    return false
  }
}

export const canReadPinboardEntry: CanActivateFn = async (route, state) => {
  const entryService = inject(PinboardEntryService);
  const entryId = `${route.paramMap.get('entry_id')}`;

  if(entryId.length < 1) {
    console.log('failing reading entry_id');
    return false;
  }

  try {
    const entry = await entryService.fetchItem(entryId);
    entryService.currentItem.set(entry);
  } catch (e) {
    console.log('failing fetching entry');
    return false;
  }

  return true;
};
