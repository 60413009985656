import {ChangeDetectionStrategy, Component, computed, effect, HostBinding, inject, OnInit, signal} from "@angular/core";
import {UserService} from "../services/user.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {UserRole} from "../types";
import {MatDivider} from "@angular/material/divider";
import {AuthService} from "../../auth/services/auth.service";
import {AppService} from "../../../services/app.service";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../services/notification.service";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";
import {AdminService} from "../../../admin/admin.service";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatLabel,
    MatIcon,
    MatFormField,
    FormsModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatDivider
  ],
  template: `
    <admin-page-header [title]="title()"/>

    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link('admin/users')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="form-container">
      <div class="flex flex-row fields-container">
        <mat-form-field>
          <mat-label>Rolle</mat-label>
          <mat-select [formControl]="roleControl">
            <mat-option value="">keine Rolle</mat-option>
            <mat-option value="user">freigeschaltet</mat-option>
            <mat-option value="mod">Moderator</mat-option>
            @if(showOptionAdmin()) {
              <mat-option value="admin">Admin</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if(canEdit()) {
          <button (click)="save()" mat-raised-button>Speichern</button>
        }
      </div>
    </div>

    <mat-divider />
  `
})
export class AdminUserRolePageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private userService = inject(UserService);
  private auth = inject(AuthService);
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private adminService = inject(AdminService);

  item = this.userService.currentItem;
  title = computed(() => `Rollen bearbeiten: ${this.item().first_name} ${this.item().last_name}`);
  link = this.nav.getSegments;
  roleControl = new FormControl<string>('');
  userRole = signal<UserRole | null>(null);
  canEdit = computed(() => {

    if (this.auth.isSystemAdmin()) {
      return true;
    }

    if(this.item().id === this.auth.userId()) {
      return false;
    }

    if(this.item().is_system_admin) {
      return false;
    }

    if (this.adminService.isAdmin()) {
      return true;
    }

    if(this.adminService.isMod()) {
      return this.userRole()?.role_name !== 'admin';
    }

    return false;
  });

  showOptionAdmin = computed(() => {

    if(!this.canEdit()) {
      return true;
    }

    return this.auth.isSystemAdmin() || this.adminService.isAdmin();
  });

  constructor() {
    effect(() => {
      if(!this.canEdit()) {
        this.roleControl.disable({emitEvent: false});
      }
    });
  }

  ngOnInit() {
    const clientId = this.adminService.clientId();
    const userId = this.item().id;
    this.userService.fetchRoles(userId).then(userRoles => {
      let userRole = userRoles.find(item => item.client_id === clientId) || null;
      this.userRole.set(userRole);
      if(userRole) {
        this.roleControl.setValue(userRole.role_name, {emitEvent: false});
      }
    });
  }

  save() {
    this.userService.setRole(
      this.adminService.clientId(),
      this.item().id,
      `${this.roleControl.value}`
    ).then(this.success).catch(this.fail);
  }

  private success = () => {
    this.note.note('Speichern erfolgreich!');
    this.nav.navigate('admin/users');
  }

  private fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
