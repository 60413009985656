import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal} from "@angular/core";
import {ComponentConfig, componentConfigFactory} from "../../../project";
import {I18nService} from "../../../i18n";
import {Asset, assetFactory, AssetService} from "../../../asset";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'article-component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MarkdownComponent
  ],
  template: `
    @if(hasHeadline()) {
      <div class="headline">{{headline()}}</div>
    }
    <div class="article-content">
      @if(hasImage()) {
      <div class="image-container">
          <img [src]="imgSrc()" alt="" />
      </div>
      }
      <div class="text-container">
        <markdown [data]="text()" />
      </div>
    </div>
  `
})
export class ArticleComponent {
  @HostBinding('class.article-component') dssClass = true;
  isDeployView = input(false);
  private i18n = inject(I18nService);
  private assetService = inject(AssetService);
  component = input<ComponentConfig>(componentConfigFactory());
  config = computed(() => {
    const component = this.component();
    const config = Object.hasOwn(component, 'config') ? component['config'] : {};
    if(!Object.hasOwn(config, 'headline')) {
      config['headline'] = JSON.stringify({});
    }
    if(!Object.hasOwn(config, 'text')) {
      config['text'] = JSON.stringify({});
    }
    if(!Object.hasOwn(config, 'image')) {
      config['image'] = assetFactory();
    }
    console.log('article config', config);
    return config;
  });
  image = computed(() => this.config()['image'] as Asset);
  hasImage = computed(() => this.image().id.length > 0);
  imgSrc = computed(() => this.assetService.getDisplayUrl(this.image()));

  headline = computed(() => this.i18n.extract(this.config()['headline'], '')());
  hasHeadline = computed(() => this.headline().length > 0);
  text = computed(() => this.i18n.extract(this.config()['text'])());
}
