import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {PinboardEditorComponent} from "./pinboard-editor.component";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Pinnwandeinstellungen" />
<!--    <div>under construction...</div>-->
    <div class="toolbar">
      <div class="button-container">
        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
      </div>
    </div>
    <div class="form-container">
      <pinboard-editor />
    </div>
  `,
  imports: [
    AdminPageHeaderComponent,
    PinboardEditorComponent,
    MatButton,
    MatIcon
  ]
})
export class PinboardEditorPageComponent {
  @HostBinding('class.admin-page') dssClass = true;
  pageEditorService = inject(PageEditorService);

  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }
}
