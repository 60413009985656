import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";
import {ProjectService} from "../../project/services/project.service";
import {AdminService} from "../../../admin/admin.service";
import {MapTagService} from "../services/map-tag.servie";

export const canActivateMapEntryList: CanActivateFn =  async (route, state) => {
  const mapEntryService = inject(MapEntryService);
  const mapTagService = inject(MapTagService);
  const projectService = inject(ProjectService);
  const adminService = inject(AdminService);

  mapTagService.filterSetClientId(adminService.clientId());
  mapEntryService.filterSetClientId(adminService.clientId());
  mapEntryService.filterSetSearch('');
  mapEntryService.filterSetModerationStatus('');
  mapEntryService.filterSetTagIds([]);

  projectService.filterSetClientId(adminService.clientId());
  projectService.filterSetSearch('');
  const response = await projectService.loadList();
  const projects = response.items;

  if (projects.length > 0) {
    mapTagService.filterSetProjectId(projects[0].id);
    mapEntryService.filterSetProjectId(projects[0].id);

    await mapTagService.loadList();
    await mapEntryService.loadList();
  }
  return true;
}

