import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {RouterModule} from "@angular/router";
import {DynamicSiteHeaderComponent} from "../features/navigation/dynamic-site-header.component";
import {NavigationService} from "../services/navigation.service";
import {AdminNavigationComponent} from "../admin/admin-navigation.component";
import {DeployService} from "./deploy.service";
import {DeployFooterComponent} from "./deploy-footer.component";

@Component({
  selector: 'deploy-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    DynamicSiteHeaderComponent,
    AdminNavigationComponent,
    DeployFooterComponent,
  ],
  template: `
    @if (canAccessAdminNavigation()) {
      <div class="admin-nav" [class.slim]="slim()">
        <app-admin-layout-navigation/>
      </div>
    }
    <div class="page-container"
         [class.nav-is-slim]="slim()"
         [class.has-no-nav]="!canAccessAdminNavigation()">
      <dynamic-site-header [isDeployView]="true"/>
      <router-outlet/>
      <deploy-footer />
    </div>
  `
})
export class DeployLayoutComponent {
  @HostBinding('class.deploy-layout') dssClass = true;
  @HostBinding('class.portal-layout') layoutClass = true;
  private nav = inject(NavigationService);
  private deployService = inject(DeployService);
  slim = this.nav.slim;
  canAccessAdminNavigation = this.deployService.canAccessAdminNavigation;
}
