export interface DynamicNavigation {
  id: string;
  nodes: Array<DynamicNavigationNode>;
}

export interface DynamicNavigationNode {
  id: string;
  // navigation_id: string;
  type: 'link_intern' | 'link_extern' | 'node';
  nodes: Array<DynamicNavigationNode>;
  target_page_id: string;
  target_url: string;
  label: string;
  name: string;
  deny_free_access: boolean; // define access without login
}

export const dynamicNavigationNodeFactory = (): DynamicNavigationNode => ({
  id: '',
  type: 'link_intern',
  nodes: [],
  target_page_id: '',
  target_url: '',
  label: '{}',
  name: '',
  deny_free_access: false,
})
