import {inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SelectComponentDialogData, SelectComponentDialogResult} from "./types";
import {SelectComponentDialogComponent} from "./select-component-dialog.component";

@Injectable({providedIn: 'root'})
export class SelectDialogService {
  private dialogService = inject(MatDialog);
  open(data: SelectComponentDialogData) {
    return new Promise<SelectComponentDialogResult>((resolve, reject) => {
      const dialogRef = this.dialogService.open<SelectComponentDialogComponent, SelectComponentDialogData, SelectComponentDialogResult>(SelectComponentDialogComponent, {data})
      dialogRef.afterClosed().subscribe(result => resolve(result as SelectComponentDialogResult));
    });
  }
}


