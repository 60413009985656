import {computed, inject, Injectable, signal} from "@angular/core";
import {CanActivateFn} from "@angular/router";
import {
  ComponentConfig,
  componentConfigFactory, PageConfig,
  pageConfigFactory,
  Project, projectConfigFactory,
} from "../../project";
import {entityArrayShiftItemDown, entityArrayShiftItemUp, uuid} from "../../../functions";
import {DynamicNavigationNode, dynamicNavigationNodeFactory} from "../../navigation/types";
import {NavigationService} from "../../../services/navigation.service";
import {ProjectService} from "../../project/services/project.service";
import {ContainerColumnConfig, containerConfigFactory} from "../components/select-component-dialog/types";

@Injectable({providedIn: 'root'})
export class PageEditorService {
  nav = inject(NavigationService);
  projectService = inject(ProjectService);
  project = this.projectService.currentItem;
  projectId = computed(() => this.project().id);
  config = signal(projectConfigFactory());
  page = signal(pageConfigFactory());
  component = signal(componentConfigFactory());

  private addComponentToContainer(component: ComponentConfig, containerId: string, columnId: string) {
    const page = this.page();
    const container = page.components.find(component => component.id === containerId);

    if(!container) {
      console.log('container not found', containerId);
      return false;
    }

    if(!Object.hasOwn(container.config, 'columns')) {
      console.log('container has no columns', containerId);
      return false
    }

    const columns = container.config['columns'] as Array<ContainerColumnConfig>;
    const column = columns.find(column => column.id === columnId);
    if(!column) {
      console.log('column not found', columnId);
      return false;
    }

    container.config['columns'] = columns.map(column => {
      if(column.id !== columnId) {
        return column;
      }
      return JSON.parse(JSON.stringify({
        ...column,
        components: [...column.components, component]
      }));
    });

    this.updateComponent(container);
    return true;
  }

  addComponent(component: ComponentConfig, containerId: string = '', rowId: string = '') {
    component.id = uuid();

    switch(component.type) {
      case 'container':
        component.config = {
          columns: [{...containerConfigFactory(),id: uuid()}],
          distance: '2rem'
        };
        break;
        // here other pre configs
    }

    // console.log('addComponent', {component, containerId, rowId});
    if(containerId.length > 0 && rowId.length > 0) {
      // console.log('addComponent to container', containerId);
      console.log('addComponent to container IMMMMM', containerId, rowId);
      if(this.addComponentToContainer(component, containerId, rowId)) {
        console.log('addComponent to container DONE');
        return;
      }
    }
    console.log('addComponent to container NOPE');

    this.page.update(page => ({
      ...page,
      components: [...page.components, component]
    }));

    this.updatePage(this.page());
  }

  addNode(node: DynamicNavigationNode) {
    console.log('addNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: [...config.navigationNodes, node]
    }));
  }

  updateNode(node: DynamicNavigationNode) {
    console.log('updateNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: config.navigationNodes.map(n => n.id !== node.id ? n : node)
    }));
  }

  deleteNode(node: DynamicNavigationNode) {
    console.log('deleteNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: config.navigationNodes.filter(n => n.id !== node.id)
    }));
  }

  addPage(page: PageConfig) {
    console.log('addPage', page);
    this.config.update(config => ({
      ...config,
      pages: [...config.pages, page]
    }));
  }

  updatePage(page: PageConfig) {
    this.config.update(config => ({
      ...config,
      pages: config.pages.map(p => p.id === page.id ? page : p)
    }));
    this.page.set(JSON.parse(JSON.stringify(page)));
  }

  deletePage(page: PageConfig) {
    this.config.update(config => ({
      ...config,
      pages: config.pages.filter(p => p.id !== page.id)
    }));
  }

  updateComponent(component: ComponentConfig) {
    // console.log('Service::updateComponent', component);

    const page = this.page();
    const pageComponent = page.components.find(c => c.id === component.id);

    if(pageComponent) {
      this.page.update(page => ({
        ...page,
        components: page.components.map(c => c.id === component.id ? component : c)
      }));
      this.updatePage(this.page());
      return;
    }

    page.components.forEach(c => {

      if(c.id === component.id) {
        return;
      }

      if(c.type === 'container') {
        const columns = c.config['columns'] as Array<ContainerColumnConfig>;
        columns.forEach(column => {
          const components = column['components'] as Array<ComponentConfig>;
          const found = components.find(c => c.id === component.id);
          if(found) {
            column['components'] = components.map(c => c.id === component.id ? component : c);
          }
        })
      }
    });

    // this.page.update(page => ({
    //   ...page,
    //   components: page.components.map(c => c.id === component.id ? component : c)
    // }));
    this.updatePage(this.page());
  }

  moveNavigationNodeUp(node: DynamicNavigationNode) {
    const nodes = this.config().navigationNodes;
    const index = nodes.findIndex(item => item.id === node.id);
    if(index < 2) {
      return;
    }
    entityArrayShiftItemUp(nodes, node);
    this.config.update(config => ({
      ...config,
        navigationNodes: nodes,
    }));
  }

  moveNavigationNodeDown(node: DynamicNavigationNode) {
    const nodes = this.config().navigationNodes;
    entityArrayShiftItemDown(nodes, node);
    this.config.update(config => ({
      ...config,
        navigationNodes: nodes,
    }));
  }

  doesNavigationNameExist(name: string): boolean {
    return this.config().navigationNodes.some(n => n.name === name);
  }

  moveComponentUp(component: ComponentConfig) {
    const components = this.page().components;
    entityArrayShiftItemUp(components, component);
    this.page.update(page => ({
      ...page,
      components: components,
    }));
    this.updatePage(this.page());
  }

  moveComponentDown(component: ComponentConfig) {
    const components = this.page().components;
    entityArrayShiftItemDown(components, component);
    this.page.update(page => ({
      ...page,
      components: components,
    }));
    this.updatePage(this.page());
  }

  deleteComponent(component: ComponentConfig) {
    const page = JSON.parse(JSON.stringify(this.page())) as PageConfig;
    const pageComponent = page.components.find(c => c.id === component.id);
    if(pageComponent) {
      this.page.update(page => ({
        ...page,
        components: page.components.filter(c => c.id !== component.id)
      }));
      this.updatePage(this.page());
      return;
    }

    page.components.forEach(c => {
        if(c.type === 'container') {
          const columns = c.config['columns'] as Array<ContainerColumnConfig>;
          columns.forEach(column => {
            const components = column['components'] as Array<ComponentConfig>;
            const found = components.find(c => c.id === component.id);
            if(found) {
              column['components'] = components.filter(c => c.id !== component.id);
            }
          })
        }
    });
    this.updatePage(page);
  }

  createNewPage() {
    const page = pageConfigFactory();
    page.id = uuid();
    this.config.update(config => ({
      ...config,
      pages: [...config.pages, page]
    }))
    return page;
  }

  getPageSegments = (pageId: string) => {
    return this.nav.getSegments(`admin/page-editor/${this.project().id}/${pageId}`);
  }

  navigateToComponent(component: ComponentConfig) {
    const projectId = this.project().id;
    const pageId = this.page().id;
    let path = `admin/component-editor/${projectId}/${pageId}/${component.type}/${component.id}`;

    switch(component.type) {
      // case 'hero-teaser': // watch for special case coz upload button in th page
      case 'article':
      case 'container':
      case 'map':
      case 'rich-map':
      case 'pinboard':
        path = `admin/component-editor/${projectId}/${pageId}/${component.id}`;
        break;
    }

    console.log('component_path', path);
    return this.nav.navigate(path);
  }

  navigateToNavigationEditor() {
    const projectId = this.project().id;
    const pageId = this.page().id;
    const path = `admin/navigation-editor/${projectId}/${pageId}`;
    return this.nav.navigate(path);
  }

  navigateToInitializedProject() {
    const projectId = this.project().id;
    const pageId = this.page().id;
    const path = `admin/page-editor/${projectId}/${pageId}`;
    return this.nav.navigate(path);
  }

  initProject(project: Project) {
    this.project.set(project);
    this.config.set(JSON.parse(project.config));
    const pages = this.config().pages;

    if (pages.length > 0) {
      this.page.set(pages[0]);

      // check for first navigation node
      if (this.config().navigationNodes.length < 1) {
        const node = dynamicNavigationNodeFactory();
        node.id = uuid();
        node.target_page_id = pages[0].id;
        this.addNode(node);
        this.save();
      }

    } else {

      const firstPage = this.createNewPage();
      this.page.set(firstPage);
      this.addPage(firstPage);

      const node = dynamicNavigationNodeFactory();
      node.id = uuid();
      node.label = JSON.stringify({de: 'Home', en: 'Home'});
      node.target_page_id = firstPage.id;
      this.addNode(node);

      this.save(); // TODO @desian: Find a better way, if time is there
    }
    this.component.set(componentConfigFactory());
  }

  save() {
    return this.projectService.save({
      ...this.project(),
      config: JSON.stringify(this.config())
    });
  }

  findComponentById(id: string): ComponentConfig | undefined {
    console.log('findComponentById', id, this.page().components);
    let returnValue: ComponentConfig | undefined = undefined;
    this.page().components.forEach(component => {

      if(returnValue) {
        return;
      }

      if(component.id === id) {
        returnValue = component;
        return;
      }

      if(component.type === 'container') {
        const columns = component.config['columns'] as Array<ContainerColumnConfig>;
        columns.forEach(column => {
          const components = column['components'] as Array<ComponentConfig>;
          const found = components.find(c => c.id === id);
          if(found) {
            returnValue = found;
          }
        });
      }
    });

    return returnValue;
    // return this.page().components.find(c => c.id === id);
  }
}

