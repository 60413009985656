import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {CookieSessionService} from "../services/cookie-session.service";
import {AppInitService} from "../services/app-init.service";

export const canActivateInitializedApp: CanActivateFn = async (route, state) => {
  console.log('canActivateInitializedApp()::start');
  inject(CookieSessionService).init('pa-session');
  await inject(AppInitService).init();
  console.log('canActivateInitializedApp()::end');
  return true;
}
