import {HasId, ListFilter, ModerationStatus, ModerationStatusFilter} from "../../types";

export interface MapEntryListFilter extends ListFilter {
  clientId: string;
  projectId: string;
  moderationStatus: ModerationStatus | '';
  tagIds: string[];
}

export const mapEntryListFilterFactory = (): MapEntryListFilter => ({
  search: '',
  clientId: '',
  projectId: '',
  moderationStatus: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc',
  tagIds: []
});

export interface MapTagListFilter extends ListFilter {
  projectId: string;
  clientId: string;
}

export const mapTagListFilterFactory = ():MapTagListFilter  => ({
  search: '',
  clientId: '',
  projectId: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc'
});

export interface MapEntry extends HasId {
  id: string;
  created: string;
  modified: string;
  client_id: string;
  project_id: string;
  tag_ids: Array<string>;
  moderation_status: ModerationStatus;
  author_name: string;
  author_email: string;
  title: string;
  short_description: string;
  config: string;
}

export const mapEntryFactory = (): MapEntry => ({
  id: '',
  created: '',
  modified: '',
  client_id: '',
  project_id: '',
  tag_ids: [],
  moderation_status: 'pending',
  author_name: '',
  author_email: '',
  title: '',
  short_description: '',
  config: '{}',
});

export interface MapTag extends HasId {
  id: string;
  client_id: string;
  project_id: string;
  created: string;
  modified: string;
  name: string;
  config: string;
}

export const mapTagFactory = (): MapTag => ({
  id: '',
  client_id: '',
  project_id: '',
  created: '',
  modified: '',
  name: '',
  config: '{}',
});

export interface MapMarkerConfig {
  latlng: L.LatLng;
  symbol: string;
  color: string;
  clickFn: (event: any) => void;
}

export interface MapEntryComment  extends HasId {
  id: string;
  created: string;
  modified: string;
  client_id: string;
  project_id: string;
  map_entry_id: string;
  moderation_status: ModerationStatus;
  author_name: string;
  author_email: string;
  content: string;
}

export const mapEntryCommentFactory = (): MapEntryComment => ({
  id: '',
  created: '',
  modified: '',
  client_id: '',
  project_id: '',
  map_entry_id: '',
  moderation_status: 'pending',
  author_name: '',
  author_email: '',
  content: '',
});

export interface MapEntryCommentListFilter extends ListFilter {
  clientId: string;
  projectId: string;
  mapEntryId: string;
  moderationStatus: ModerationStatusFilter;
}

export const mapEntryCommentListFilterFactory = (): MapEntryCommentListFilter => ({
  search: '',
  clientId: '',
  projectId: '',
  mapEntryId: '',
  moderationStatus: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc',
});
