import {computed, inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {Asset, AssetListFilter} from "./types";
import {ListResponse} from "../../types";
import {ApiService} from "../../services/api.service";
import {AppService} from "../../services/app.service";


@Injectable({providedIn: "root"})
export class AssetService {
  private http = inject(HttpClient);
  private api = inject(ApiService);
  private app = inject(AppService);
  assetTypesImage = [
    'image/jpe',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/svg',
    'image/svg+xml',
  ];
  assetTypesPdf = 'application/pdf';
  assetTypesAccepted = [
    ...this.assetTypesImage,
    this.assetTypesPdf
  ].join(',');

  public filter = signal<AssetListFilter>({
    search: '',
    page: 0,
    size: 50,
    orderBy: 'created',
    direction: 'desc',
    clientId : ''
  });

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }

  private listResponse = signal<ListResponse<Asset>>({
    items: [],
    total: 0
  });
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  async upload(file: File, clientId: string) {
    const formData = new FormData();
    formData.append("field", file);
    // formData.append("client_id", this.app.activeClient().id);
    // return firstValueFrom(this.http.post<Asset>(this.api.apiUrl(`asset/${this.app.activeClient().id}`), formData));
    return firstValueFrom(this.http.post<Asset>(this.api.apiUrl(`asset/upload/${clientId}`), formData, {headers: this.api.headers()}));
    // return firstValueFrom(this.api.post<Asset>(this.api.apiUrl(`asset/upload/${clientId}`), formData));
  }

  getDisplayUrl = (asset: Asset): string => {
    return `${this.api.apiUrl('asset')}/${asset.id}`;
  }

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('client_id', `${this.filter().clientId}`);
  }

  async fetchList() {
    const response = this.api.fetchList<Asset>('asset', this.listParams);
    response.then(value => this.listResponse.set(value as ListResponse<Asset>));
    response.catch(error => console.log('AssetService::fetchList()', error));
    return response;
  }

  async deleteById(id: string){
    return this.api.deleteById<Asset>('asset', id, true);
  }
}
