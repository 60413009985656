import {computed, inject, Injectable, signal} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {MapEntry, mapEntryFactory, mapEntryListFilterFactory} from "../types";
import {listResponseFactory, ModerationStatus} from "../../../types";
import {HttpParams} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MapEntryService {
  private api= inject(ApiService);
  private listResponse = signal(listResponseFactory<MapEntry>());

  public currentItem = signal(mapEntryFactory());
  public filter = signal(mapEntryListFilterFactory());
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }
  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }
  filterSetProjectId(projectId: string) {
    this.filter.update(value => ({...value, projectId}));
  }
  filterSetModerationStatus(moderationStatus: ModerationStatus | '') {
    this.filter.update(value => ({...value, moderationStatus}));
  }
  filterSetTagIds(tagIds: Array<string>) {
    this.filter.update(value => ({...value, tagIds}));
  }

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('moderation_status', `${this.filter().moderationStatus}`)
      .set('tag_ids', `${this.filter().tagIds.join(',')}`)
      ;
  }

  async loadList() {
    const response = await this.api.fetchList<MapEntry>('mapentry', this.listParams);
    this.listResponse.set(response);
    return response;
  }

  async fetchItem(id: string){
    return this.api.fetchById<MapEntry>('mapentry', id, true);
  }

  async save(item: MapEntry): Promise<MapEntry> {
    return this.api.save<MapEntry>('mapentry', item, true);
  }

  async deleteById(id: string) {
    return this.api.deleteById<MapEntry>('mapentry', id, true);
  }
}
