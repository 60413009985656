import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {PageEditorService} from "../services/page-editor.service";

export const canEditComponent: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const component = pageEditorService.findComponentById(`${route.paramMap.get('component_id')}`);

  if(!component) {
    console.log('component id not found');
    return false;
  }


  console.log('canEditComponent:found', component);
  pageEditorService.component.set(component);
  return true;
};
