import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardEntryCommentService} from "../services/pinboard-entry-comment.service";

export const canPinboardEntryCommentListPage: CanActivateFn =  async (route, state) => {
  const entryService = inject(PinboardEntryService);
  const commentService = inject(PinboardEntryCommentService);
  const entryId = `${route.paramMap.get('entryId')}`;
  const entry = await entryService.fetchItem(entryId);
  commentService.setEntry(entry);
  await commentService.loadList();
  return true;
}
