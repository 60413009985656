import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {ProjectService} from "./services/project.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {AdminProjectDetailFormComponent} from "./admin-project-detail-form.component";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-project-detail-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    ReactiveFormsModule,
    MatIcon,
    RouterLink,
    MatButton,
    AdminProjectDetailFormComponent
  ],
  template: `
    <admin-page-header [title]="title()"/>

    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link('admin/projects')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="form-container">
      <admin-project-detail-form
        (saved)="onSaved()"
        (deleted)="onDeleted()"/>
    </div>
  `,
})
export class AdminProjectDetailPageComponent {
  @HostBinding('class.admin-page') dssClass = true;
  private nav = inject(NavigationService);
  private service = inject(ProjectService);
  link = this.nav.getSegments;
  item = this.service.currentItem;
  title = computed(() =>
    this.item().id.length > 0
      ? 'Diskussionskreis bearbeiten'
      : 'Diskussionskreis erstellen'
  );

  onSaved() {
    console.log('saved');
    this.nav.navigate('admin/projects');
  }

  onDeleted() {
    console.log('deleted');
    this.nav.navigate('admin/projects');
  }
}
