import {ComponentConfig} from "../../../project";

export interface SelectComponentDialogData {
  containerId: string;
  columnId: string;
  denyContainer: boolean;
}

export interface SelectComponentDialogResult {
  containerId: string;
  columnId: string;
  type: string;
}

export interface ContainerColumnConfig {
  id: string;
  components: Array<ComponentConfig>;
  gridColumnWidth: string;
}

export const containerConfigFactory = ():ContainerColumnConfig => ({
  id: '',
  components: [],
  gridColumnWidth: '1fr'
});
