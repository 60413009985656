import {inject, Injectable, PLATFORM_ID, signal} from "@angular/core";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Injectable({providedIn: "root"})
export class AppService {
  private platformId = inject(PLATFORM_ID);
  isServer = signal(isPlatformServer(this.platformId));
  isBrowser = signal(isPlatformBrowser(this.platformId));
  isRouteDeployView = signal(false);
}
