import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {ChangeDetectionStrategy, Component, HostBinding, inject, signal} from "@angular/core";
import {RichMapContainerComponent} from "../map/components/rich-map-container.component";
import {ClientService} from "../client";
import {AssetService} from "../asset";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-layout-navigation, [app-admin-layout-navigation]',
  standalone: true,
  imports: [
    MatListModule,
    MatButtonModule,
    AdminPageHeaderComponent,
    RichMapContainerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Develop Test"/>
    <div class="page-content-container">
<!--      <i18n-text-input [(value)]="testValue"-->
<!--                       [required]="true"-->
<!--                       label="Test Label"-->
<!--                       errorMessage="Error happen"/>-->
<!--      <i18n-textarea  [(value)]="testValue"-->
<!--                      [required]="true"-->
<!--                      label="Test Label"-->
<!--                      errorMessage="Error happen" />-->
<!--      <hr/>-->
<!--      <pre>outside: {{ testValue() }}</pre>-->
<!--      <hr/>-->
            <rich-map-container />

<!--      <button (click)="clickAction()" mat-button> test here</button>-->


<!--      <input #uploadField hidden="hidden" type="file"-->
<!--             onclick="this.value=null"-->
<!--             (change)="onFileSelected($event)" accept="image/jpeg,image/png,image/svg"/>-->
<!--      <button mat-flat-button color="primary" (click)="uploadField.click()">Upload</button>-->
<!--      <hr />-->
<!--      @if(assetSaved()) {-->
<!--        <img [src]="assetUrl()" alt="" />-->
<!--      }-->

    </div>
  `
})
export class DevTestPageComponent {
  @HostBinding('class.admin-page') classAdminPage = true;
  private clientService = inject(ClientService);
  private assetService = inject(AssetService);
  assetUrl = signal('');
  assetSaved = signal(false);

  testLabel = signal('Bla Foo');
  testValue = signal(JSON.stringify({de: 'test value', en: 'en value'}));

  clickAction = () => {
    console.log('clicked');
    this.clientService.fetchList().then(response => console.log('response', response));
  }

  onFileSelected(event: any) { // DOM file select event
    console.log('onFileSelected', event);
    // const file:File = event.target.files[0];
    // if (file) {
    //   this.assetService.upload(file).then(asset => {
    //     console.log('saved', asset);
    //     this.assetUrl.set(this.assetService.getDisplayUrl(asset));
    //     this.assetSaved.set(true);
    //   });
    // }
  }
}
