import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal} from "@angular/core";
import {PageEditorService} from "../../services/page-editor.service";
import {ContainerColumnConfig, containerConfigFactory} from "../select-component-dialog/types";
import {uuid} from "../../../../functions";
import {MatDivider} from "@angular/material/divider";
import {PageEditorComponentContainerColumnFormComponent} from "./page-editor-component-container-column-form.component";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'component-container-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    PageEditorComponentContainerColumnFormComponent,
    MatButtonModule,
  ],
  template: `
    <p>
      Die Weite der Spalten kann in relativen oder in absoluten Einheiten angegeben werden.<br />
      Es sind die Einheiten, wie sie auch im CSS verwendet werden.<br />
      "150px" sind genauso möglich, wie "10rem" oder "1fr".<br />
      "fr" bedeutet Anteile.<br />
      Eine Spalte mit "1fr" und eine Spalte mit "3fr" bedeutet, dass die erste Spalte 25% und die zweite 75% der Breite beansprucht.<br />
      Alle Spalten mit "1fr" bedeuten, dass die Breite gleicher Maaßen aufgeteilt wird.<br />
      (default: "1fr")
    </p>
    <mat-divider />
    <h2>Spalten</h2>
    @for (column of columns(); track column) {
      <component-container-column-form [column]="column"
                                       (updated)="updateColumn($event)"
                                       (deleted)="deleteColumn($event)" />
    }
    <button (click)="addColumn()" mat-raised-button>Spalte hinzufügen</button>
  `
})
export class PageEditorComponentContainerFormComponent implements OnInit {
  @HostBinding('class.component-container-form') cssClass = true;
  private pageEditorService = inject(PageEditorService);
  component = this.pageEditorService.component;
  columns = signal<Array<ContainerColumnConfig>>([]);
  distance = signal('');

  addColumn() {
    this.columns.set([...this.columns(), {...containerConfigFactory(),id: uuid()}]);
    const component = this.component();

    const updatedComponent = {
      ...component,
      config: {
        ...component.config,
        columns: this.columns(),
      }
    };

    this.pageEditorService.updateComponent(updatedComponent);
  }

  updateColumn = (column: ContainerColumnConfig)=> {
    const component = this.component();
    const updatedComponent = {
      ...component,
      config: {
        ...component.config,
        columns: this.columns().map(c => c.id === column.id ? column : c),
      }
    };
    console.log('updateColumn', column);
    this.pageEditorService.updateComponent(updatedComponent);
  }

  deleteColumn = (column: ContainerColumnConfig)=> {
    const component = this.component();
    const updatedComponent = {
      ...component,
      config: {
        ...component.config,
        columns: this.columns().filter(c => c.id !== column.id),
      }
    }
    console.log('deleteColumn', column, updatedComponent);
    this.columns.set(updatedComponent.config.columns);
    this.pageEditorService.updateComponent(updatedComponent);
  }

  ngOnInit() {
    console.log('component', this.component());
    const config = this.component().config || {};
    if(Object.hasOwn(config, 'columns')) {
      this.columns.set(config['columns']);
    } else {
      this.columns.set([{...containerConfigFactory(),id: uuid()}]);
    }
    if(Object.hasOwn(config, 'distance')) {
      this.distance.set(config['distance']);
    } else {
      this.distance.set('2rem');
    }
  }
}
