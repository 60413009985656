import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  OnInit,
  output,
} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MatOption, MatSelect} from "@angular/material/select";
import {PinboardTagService} from "../services/pinboard-tag.service";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardEntry, pinboardEntryFactory} from "../types";
import {NotificationService} from "../../../services/notification.service";
import {DeployService} from "../../../deploy/deploy.service";
import {componentConfigFactory} from "../../project";
import {AuthService} from "../../auth/services/auth.service";
import {AdminService} from "../../../admin/admin.service";
import {PinboardEntrySavedEvent} from "../events/pinboard-entry-saved.event";

@Component({
  selector: 'pinboard-entry-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatLabel,
    MatButton,
    MatSelect,
    MatOption,
  ],
  template: `
    <div class="component-title">Eintrag</div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input [formControl]="authorNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Überschrift</mat-label>
      <input [formControl]="titleControl" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Kurzbeschreibung</mat-label>
      <textarea [formControl]="shortDescriptionControl" rows="2" matInput></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Artikel-Text</mat-label>
      <textarea [formControl]="contentControl" rows="6" matInput></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-select [formControl]="tagsControl" multiple>
        @for (tag of tags(); track tag) {
          <mat-option [value]="tag.id">{{tag.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="button-container">
        <button (click)="save()" mat-button>Absenden</button>
    </div>
<!--    <pre>tags() {{tags() | json}}</pre>-->
<!--    <pre>pinboardComponent() {{pinboardComponent() | json}}</pre>-->
  `
})
export class PinboardEntryFormComponent implements OnInit {
  @HostBinding('class.pinboard-entry-form') cssCLass = true;
  @HostBinding('class.form-container') cssFormContainer = true;
  pinboardComponent = input(componentConfigFactory())
  isDeployView = input(false);
  clientId = input<string>('');
  projectId = input<string>('');
  private savedEvent = inject(PinboardEntrySavedEvent);
  saved = output<PinboardEntry>();

  private pinboardTagService = inject(PinboardTagService);
  private service = inject(PinboardEntryService);
  private note = inject(NotificationService);
  private auth = inject(AuthService);
  private adminService = inject(AdminService)
  private deployService = inject(DeployService);

  tags = this.pinboardTagService.items;

  authorNameControl = new FormControl('');
  authorEmailControl = new FormControl('');
  titleControl = new FormControl('');
  shortDescriptionControl = new FormControl('');
  contentControl = new FormControl('');
  tagsControl = new FormControl<Array<string>>([]);
  isUserActivated = computed(() => this.isDeployView()
    ? this.deployService.isUserActivated()
    : this.adminService.isUserActivated());

  ngOnInit() {
    this.pinboardTagService.filterSetProjectId(this.projectId());
    this.pinboardTagService.filterSetClientId(this.clientId());
    this.pinboardTagService.loadList()
      .then(() => this.reset());
  }

  reset() {
    this.authorNameControl.reset();
    this.authorEmailControl.reset();
    this.titleControl.reset();
    this.shortDescriptionControl.reset();
    this.contentControl.reset();
    this.tagsControl.reset();
    const config = this.pinboardComponent().config;
    if(Object.hasOwn(config, 'tag_ids')) {
      this.tagsControl.reset(config['tag_ids'] as Array<string>);
    }

    if(this.isUserActivated()) {
      this.authorEmailControl.setValue(this.auth.userEmail(), {emitEvent: false});
      this.authorEmailControl.disable();
      this.authorNameControl.setValue(this.auth.userName(), {emitEvent: false});
      this.authorNameControl.disable();
    }
  }

  save() {
    const newItem: PinboardEntry = {
      ...pinboardEntryFactory(),
      client_id: this.clientId(),
      project_id: this.projectId(),
      tag_ids: this.tagsControl.value || [],
      author_name: this.authorNameControl.value || '',
      author_email: this.authorEmailControl.value || '',
      title: this.titleControl.value || '',
      short_description: this.shortDescriptionControl.value || '',
      content: this.contentControl.value || '',
      moderation_status: this.isUserActivated() ? 'approved' : 'pending',
    };
    console.log('save item', newItem);

    this.service.save(newItem)
      .then(this.success)
      .catch(this.fail);
  }

  success = (item: PinboardEntry) => {
    this.note.note('Gespeichert!');
    this.reset();
    this.saved.emit(item);
    this.savedEvent.emit(item);
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
