import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {AuthPasswordResetService} from "../services/auth-password-reset.service";

export const canPasswordResetConfirm: CanActivateFn =  async (route, state) => {
  const registerService = inject(AuthPasswordResetService);
  registerService.resetKey.set(`${route.paramMap.get('resetKey')}`);
  try {
    const response = await registerService.passwordResetCheckKey({
      reset_key: registerService.resetKey()
    });
    registerService.checkResponse.set(response);
  } catch (e) {
    console.log('canPasswordResetConfirm::checkResponse:', e);
  }
  return true;
}
