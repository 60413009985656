import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {ProjectService} from "../../project/services/project.service";
import {PinboardTagService} from "../services/pinboard-tag.service";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AdminService} from "../../../admin/admin.service";
import {PinboardTag, pinboardTagFactory} from "../types";
import {NotificationService} from "../../../services/notification.service";
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatChip, MatChipSet} from "@angular/material/chips";

@Component({
  selector: 'admin-pinboard-tag-admin',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatChipSet,
    MatChip,
    MatError,
  ],
  template: `
    <p class="mat-headline-large">Pinnwand TAGs</p>
    <div class="tag-form">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>TAG Name</mat-label>
          <input [formControl]="tagControl" matInput />
          <mat-error>Kann nicht leer sein.</mat-error>
        </mat-form-field>
      </div>

      <div class="button-container">
        <button (click)="saveTag()" mat-raised-button>Erstellen</button>
      </div>
    </div>
    <div class="tag-list">
      <mat-chip-set>
        @for (tag of tags(); track tag) {
          <mat-chip>{{tag.name}}</mat-chip>
        }
      </mat-chip-set>
    </div>
  `
})
export class AdminPinboardTagAdminComponent implements OnInit {
  @HostBinding('class.admin-pinboard-tag-admin') cssClass = true;
  private note = inject(NotificationService);
  private adminService = inject(AdminService);
  private projectService = inject(ProjectService);
  private pinboardTagService = inject(PinboardTagService);
  project = this.projectService.currentItem;
  tags = this.pinboardTagService.items;
  tagControl = new FormControl('', [Validators.required]);

  ngOnInit() {
    this.pinboardTagService.filterSetClientId(this.adminService.clientId());
    this.pinboardTagService.filterSetProjectId(this.project().id);
    this.pinboardTagService.loadList();
  }

  saveTag() {
    const value = `${this.tagControl.value}`;
    this.tagControl.markAllAsTouched();
    if (!this.tagControl.valid) {
      return;
    }

    const newTag: PinboardTag =  {
      ...pinboardTagFactory(),
      client_id: this.adminService.clientId(),
      project_id: this.project().id,
      name: value
    };

    this.pinboardTagService.save(newTag)
      .then(this.saveTagSuccess)
      .catch(this.saveTagFail)
  }

  saveTagSuccess = () => {
    this.note.note('Gespeichert!');
    this.tagControl.reset();
    this.pinboardTagService.loadList();
  }

  saveTagFail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
