import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  OnInit,
  signal
} from "@angular/core";
import {ComponentConfig, componentConfigFactory} from "../../../project";
import {assetFactory, AssetService} from "../../../asset";

@Component({
  selector: 'hero-teaser',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  template: `
    @if (hasAsset()) {
      <img [src]="src()" alt="" />
    } @else {
      <div class="placeholder">Bild auswählen</div>
    }
  `
})
export class HeroTeaserComponent implements OnInit {
  @HostBinding('class.hero-teaser') cssCLass = true;
  isDeployView = input(false);
  private assetService = inject(AssetService);
  component = input<ComponentConfig>(componentConfigFactory());
  asset = signal(assetFactory());
  hasAsset = computed(() => this.asset().id.length > 0);
  src = computed(() => this.hasAsset() ? this.assetService.getDisplayUrl(this.asset()) : '');

  ngOnInit() {
    const config = this.component().config;
    console.log('check for asset');
    if(Object.hasOwnProperty.call(config, 'asset')) {
      console.log('asset key found');
      this.asset.set(config['asset']);
    }
  }
}
