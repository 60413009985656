import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {projectFactory} from "../types";
import {ProjectService} from "../services/project.service";

export const canEditProject: CanActivateFn = async (route, state) => {
  const service = inject(ProjectService);
  const projectId = `${route.paramMap.get('id')}`;

  if(projectId.length < 1) {
    console.log('canEditProject', 'failing reading id');
    service.currentItem.set(projectFactory());
    return true;
  }

  try {
    const item = await service.fetchItem(`${route.paramMap.get('id')}`);
    service.currentItem.set(item);
    return true;
  } catch {
    return false;
  }
};
