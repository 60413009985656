import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from "@angular/core";
import {ProjectService} from "./services/project.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminPinboardTagAdminComponent} from "../pinboard/components/admin-pinboard-tag-admin.component";
import {AdminMapTagAdminComponent} from "../map/components/admin-map-tag-admin.component";

@Component({
  selector: 'project-tag-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatIcon,
    ReactiveFormsModule,
    AdminPinboardTagAdminComponent,
    AdminMapTagAdminComponent
  ],
  template: `
    <admin-page-header [title]="title()" />
    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link('admin/projects')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="content-container">
      <admin-pinboard-tag-admin />
      <admin-map-tag-admin />
    </div>
  `
})

export class AdminProjectTagPageComponent {
  @HostBinding('class.project-tag-page') cssClass = true;
  @HostBinding('class.admin-page') classAdminPage = true;
  private projectService = inject(ProjectService);
  private nav = inject(NavigationService);
  link = this.nav.getSegments;
  project = this.projectService.currentItem;
  title = computed(() => `TAG Verwaltung für Projekt "${this.project().title}"`);
}
