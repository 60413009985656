import {ChangeDetectionStrategy, Component, HostBinding} from "@angular/core";

@Component({
  selector: 'deploy-footer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  template: `
  <div class="logos">
    <img src="/img/Logo-Rot-Vektor-volldurchsichtig.svg" alt="Logo Politaktiv" />
    <img src="/img/HIT-BB-Logo-farbe-freigestellt.png" alt="Logo Human IT" />
  </div>
  <div class="disclaimer">
    <p>
      Die Website ist Teil der Online-Plattform <a href="https://www.politaktiv.org/" target="_blank">Politaktiv</a>.<br />
      Sie wird betrieben von der <a href="https://www.hit-buergerbeteiligung.de/" target="_blank">Human IT Service GmbH</a>.
    </p>
  </div>
  <div class="links">
    <p>
      <a href="https://www.hit-buergerbeteiligung.de/datenschutzerklaerung/">Datenschutzerklärung</a><br />
      <a href="https://www.hit-buergerbeteiligung.de/impressum">Impressum</a>
    </p>
  </div>
  `
})
export class DeployFooterComponent {
  @HostBinding('class.deploy-footer') cssClass = true;
}
