import {computed, inject, Injectable, signal} from '@angular/core';
import {User, userFactory, UserRole} from "../index";
import {ListFilter, ListResponse} from "../../../types";
import {HttpParams} from "@angular/common/http";
import {CanActivateFn} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {AppService} from "../../../services/app.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api = inject(ApiService);
  private app = inject(AppService);
  public currentItem = signal(userFactory());

  public filter = signal<ListFilter>({
    search: '',
    page: 0,
    size: 50,
    orderBy: 'email',
    direction: 'asc'
  });

  private listResponse = signal<ListResponse<User>>({
    items: [],
    total: 0
  });

  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  // roles = signal([
  //   'user', 'admin', 'mod'
  // ]);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`);
  }

  fetchList() {
    const response = this.api.fetchList<User>('user', this.listParams, true);
    response.then(value => this.listResponse.set(value));
    response.catch(error => console.log('ClientService::fetchList()', error));
    return response;

  }

  async fetchById(id: string) {
    return this.api.fetchById<User>('user', id, true);
  }

  async save(item: User): Promise<User> {
    return this.api.save<User>('user', item, true);
  }

  async setRole(client_id: string, user_id: string, role: string) {
    return this.api.post<{status: string}>('user-set-role', {client_id, user_id, role}, true);
  }

  async fetchRoles(id: string) {
    return this.api.fetch<Array<UserRole>>(`user-roles/${id}?_dc=${Date.now()}`, true);
  }
}

export const canEditUser: CanActivateFn = async (route, state) => {
  const service = inject(UserService);
  try {
    const user = await service.fetchById(`${route.paramMap.get('id')}`);
    service.currentItem.set(user);
  } catch {
    service.currentItem.set(userFactory());
  }
  return true;
};

export const canCreateUser: CanActivateFn = (route, state) => {
  inject(UserService).currentItem.set(userFactory());
  return true;
};
