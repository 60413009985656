import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input} from "@angular/core";
import {PageEditorService} from "../../services/page-editor.service";
import {AdminService} from "../../../../admin/admin.service";
import {ComponentConfig, componentConfigFactory} from "../../../project";
import {RichMapContainerComponent} from "../../../map/components/rich-map-container.component";
import {PinboardComponent} from "../../../pinboard/components/pinboard.component";
import {HeroTeaserComponent} from "../hero-teaser/hero-teaser.component";
import {ArticleComponent} from "../article/article.component";
import {PinboardEntryFormComponent} from "../../../pinboard/components/pinboard-entry-form.component";
import {PageEditorComponentMenuComponent} from "../page-editor-component-menu.component";
import {ContainerColumnConfig, SelectComponentDialogResult} from "../select-component-dialog/types";
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu";
import {SelectDialogService} from "../select-component-dialog/select-dialog.service";

@Component({
  selector: 'page-editor-component-container-column',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RichMapContainerComponent,
    PinboardComponent,
    HeroTeaserComponent,
    ArticleComponent,
    PinboardEntryFormComponent,
    PageEditorComponentMenuComponent,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuModule,
  ],
  template: `
    @for (component of components(); track component) {
      @switch (component.type) {
        @case ('hero-teaser') {
          <div class="page-editor-component-wrapper component-wrapper">
            <hero-teaser [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('rich-map') {
          <div class="page-editor-component-wrapper component-wrapper">
            <rich-map-container [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('map') {
          <div class="page-editor-component-wrapper">
            <rich-map-container [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('pinboard') {
          <div class="page-editor-component-wrapper component-wrapper">
            <pinboard [component]="component"
                      [clientId]="clientId()"
                      [projectId]="projectId()" />
            <pinboard-entry-form [pinboardComponent]="component"
                                 [clientId]="clientId()"
                                 [projectId]="projectId()" />
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('article') {
          <div class="page-editor-component-wrapper component-wrapper">
            <article-component [component]="component" />
            <page-editor-component-menu [component]="component"/>
          </div>
        }
      }
    }

    <div class="component-container-column-menu-container">
      <button [matMenuTriggerFor]="menu" mat-icon-button><mat-icon>settings</mat-icon></button>
      <mat-menu #menu="matMenu" class="mat-menu-fix">
        <div class="no-hover" mat-menu-item>Spalten Menu</div>
        <mat-divider />
        <button (click)="openComponentSelector()" mat-menu-item>Komponente hinzufügen</button>
      </mat-menu>
    </div>
  `
})
export class PageEditorComponentContainerColumnComponent {
  @HostBinding('class.page-editor-component-container-column') cssClass = true;
  @HostBinding('class.component-container-column') containerClass = true;
  private service = inject(PageEditorService);
  private adminService = inject(AdminService);
  private selectDialogService = inject(SelectDialogService);
  clientId = this.adminService.clientId;
  projectId = this.service.projectId;
  config = this.service.config;
  container = input.required<ComponentConfig>();
  column = input.required<ContainerColumnConfig>();
  components = computed(() => this.column().components);

  openComponentSelector() {
    console.log('openComponentSelector', this.container().id, this.column().id);
    this.selectDialogService
      .open({containerId: this.container().id, columnId: this.column().id, denyContainer: true})
      .then(this.afterComponentSelect);
  }

  afterComponentSelect = (result: SelectComponentDialogResult) => {
    console.log('afterComponentSelect', result);
    switch (result.type) {
      case 'hero-teaser':
      case 'article':
      case 'pinboard':
      case 'map':
      case 'container':
        this.service.addComponent({
          ...componentConfigFactory(),
          type: result.type,
        }, result.containerId, result.columnId);
        break;
    }
  }
}
