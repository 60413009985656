import {inject, Injectable} from "@angular/core";
import {CanActivateFn} from "@angular/router";
import {PageEditorService} from "../../services/page-editor.service";

@Injectable({providedIn: 'root'})
export class HeroTeaserService {

}

export const canEditHeroTeaser: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const componentId = `${route.paramMap.get('component_id')}`;
  const component = pageEditorService.findComponentById(componentId);
  if(component !== undefined) {
    pageEditorService.component.set(component);
    return true;
  } else {
    console.log('component id not found');
    return false
  }
}


