import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";

export const canReadMapEntry: CanActivateFn = async (route, state) => {
  const mapEntryService = inject(MapEntryService);
  const entryId = `${route.paramMap.get('entry_id')}`;

  if(entryId.length < 1) {
    console.log('failing reading entry_id');
    return false;
  }

  try {
    const entry = await mapEntryService.fetchItem(entryId);
    mapEntryService.currentItem.set(entry);
  } catch (e) {
    console.log('failing fetching entry');
    return false;
  }

  return true;
};
