import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input} from "@angular/core";
import {ComponentConfig} from "../../project";
import {PageEditorService} from "../services/page-editor.service";
import {NotificationService} from "../../../services/notification.service";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";

@Component({
  selector: 'page-editor-component-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
  ],
  template: `
    <button [matMenuTriggerFor]="menu" mat-icon-button><mat-icon>settings</mat-icon></button>
    <mat-menu #menu="matMenu" class="mat-menu-fix">
      <div mat-menu-item>{{componentTypeName()}}</div>
      <mat-divider />

      <button (click)="moveComponentUp()" mat-menu-item><mat-icon>arrow_upward</mat-icon> Verschieben</button>
      <button (click)="moveComponentDown()" mat-menu-item><mat-icon>arrow_downward</mat-icon> Verschieben</button>
      <button (click)="editComponent()" mat-menu-item>Bearbeiten</button>
      <button (click)="deleteComponent()" mat-menu-item>Löschen</button>
      @if(isContainer()) {
<!--        <mat-divider />-->
<!--        <div>Container options</div>-->
      }
    </mat-menu>
  `
})
export class PageEditorComponentMenuComponent {
  @HostBinding('class.page-editor-component-menu') cssClass = true;
  component = input.required<ComponentConfig>();
  private note = inject(NotificationService);
  private service = inject(PageEditorService);
  componentTypeName = computed(() => this.component().type);
  isContainer = computed(() => this.component().type === 'container');

  editComponent() {
    this.service.navigateToComponent(this.component());
  }

  deleteComponent() {
    this.note.confirm('Komponente löschen?', () => {
      this.service.deleteComponent(this.component());
    });
  }

  moveComponentUp() {
    this.service.moveComponentUp(this.component());
  }

  moveComponentDown() {
    this.service.moveComponentDown(this.component());
  }
}
