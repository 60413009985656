import {computed, inject, Injectable, signal} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {listResponseFactory, ModerationStatusFilter} from "../../../types";
import {
  MapEntry,
  MapEntryComment,
  mapEntryCommentFactory,
  mapEntryCommentListFilterFactory,
  mapEntryFactory
} from "../types";
import {HttpParams} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MapEntryCommentService {
  private api = inject(ApiService);
  private listResponse = signal(listResponseFactory<MapEntryComment>());
  currentItem = signal(mapEntryCommentFactory())
  filter = signal(mapEntryCommentListFilterFactory());
  items = computed(() => this.listResponse().items);
  total = computed(() => this.listResponse().total);
  entry = signal(mapEntryFactory());
  setEntry(entry: MapEntry) {
    this.entry.set(entry);
    this.filterSetClientId(entry.client_id);
    this.filterSetProjectId(entry.project_id);
    this.filterSetMapEntryId(entry.id);
  }
  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('map_entry_id', `${this.filter().mapEntryId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('moderation_status', `${this.filter().moderationStatus}`)
      ;
  }
  filterSetClientId(id: string) {
    this.filter.update(filter => ({...filter, clientId: id}));
  }

  filterSetProjectId(projectId: string) {
    this.filter.update(filter => ({...filter, projectId}));
  }

  filterSetMapEntryId(mapEntryId: string) {
    this.filter.update(filter => ({...filter, mapEntryId}));
  }
  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetModerationStatus(moderationStatus: ModerationStatusFilter) {
    this.filter.update(filter => ({...filter, moderationStatus}));
  }

  async loadList() {
    const response = await this.api.fetchList<MapEntryComment>('mapentrycomment', this.listParams, true);
    this.listResponse.set(response);
    return response;

  }

  async fetchItem(id: string){
    return this.api.fetchById<MapEntryComment>('mapentrycomment', id, true);
  }

  async save(item: MapEntryComment): Promise<MapEntryComment> {
    return this.api.save<MapEntryComment>('mapentrycomment', item, true);
  }

  async deleteById(id: string){
    return this.api.deleteById<MapEntryComment>('mapentrycomment', id, true);
  }
}
