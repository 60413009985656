import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AssetService} from "./asset.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {AdminAssetListComponent} from "./admin-asset-list.component";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    FormsModule,
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    AdminAssetListComponent
  ],
  template: `
    <admin-page-header title="Assets" />
    <div class="toolbar">

      <div class="flex button-container">
        <input #uploadField hidden="hidden" type="file"
               onclick="this.value=null"
               (change)="onFileSelected($event)"
                [accept]="accepted"/>
<!--               accept="image/jpeg,image/png,image/svg"/>-->
<!--        <button mat-flat-button color="primary" (click)="uploadField.click()">Upload</button>-->



        <button (click)="uploadField.click()" mat-button>
          <mat-icon>upload</mat-icon>
          Upload (max 5MB)
        </button>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>

    <div class="list-container">
      <admin-asset-list />
    </div>
  `
})
export class AdminAssetListPageComponent implements OnInit {
  @HostBinding('class.admin-page') cssClass = true;
  private service = inject(AssetService);
  private adminService = inject(AdminService);
  private note = inject(NotificationService);
  accepted = this.service.assetTypesAccepted;

  public search = new FormControl(
    this.service.filter().search
  );

  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.fetchList();
  }

  onFileSelected(event: any) {
    const file:File = event.target.files[0];
    if (file) {
      if (file.size < 1) {
        this.note.note('Dateigröße für Upload nicht feststellbar!');
        return;
      }
      if (file.size > 1024 * 1024 * 5) {
        this.note.note('Datei ist zu groß! Limit ist 5MB.');
        return;
      }
      this.service
        .upload(file, this.adminService.clientId())
        .then(this.reload);
    }
  }

  ngOnInit() {
    this.service.filterSetClientId(this.adminService.clientId());
    this.service.fetchList();
  }
}
