import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {AdminNavigationComponent} from "./admin-navigation.component";
import {NavigationService} from "../services/navigation.service";
import {AdminService} from "./admin.service";
import {AuthService} from "../features/auth/services/auth.service";
import {DeployService} from "../deploy/deploy.service";

@Component({
  selector: 'app-admin-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    AdminNavigationComponent,
  ],
  template: `
    @if (canAccessNavigation()) {
      <div class="admin-nav" [class.slim]="slim()">
        <app-admin-layout-navigation/>
      </div>
    }
    <div class="page-container"
         [class.nav-is-slim]="slim()"
         [class.has-no-nav]="!canAccessNavigation()">
      <router-outlet/>
    </div>
  `,
})
export class AdminLayoutComponent {
  @HostBinding('class.portal-layout') layoutClass = true;
  private nav = inject(NavigationService);
  private auth = inject(AuthService);
  private adminService = inject(AdminService);
  private deployService = inject(DeployService);
  slim = this.nav.slim;
  canAccessNavigation = computed(
    () => this.adminService.canAccessAdminNavigation()
      || !this.deployService.hasActiveDeploy()
      && this.auth.rolesForAdminAccess().length > 0
  );
}
