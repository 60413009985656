export const environment =  {
  envName: 'dev',
  production: false,
  noBackend: false,
  defaultLocaleCode: 'de',
  // apiUrl: 'https://api.cms.dev.politaktiv.org/api',
  apiUrl: 'https://web.cms.dev.politaktiv.org/api',
  keycloak: {
    enabled: true,
    config: {
      url: 'https://auth.dev.pa2.dynamic-projects.org/auth',
      realm: 'politaktiv2',
      clientId: 'paclient'
    }
  }
}
