import {Component, computed, HostBinding, inject} from "@angular/core";
import {PageEditorService} from "../services/page-editor.service";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {ArticleEditorComponent} from "./article/article-editor.component";
import {
  PageEditorComponentContainerFormComponent
} from "./component-container/page-editor-component-container-form.component";
import {RichMapEditorComponent} from "../../map/components/rich-map-editor.component";
import {PinboardEditorComponent} from "../../pinboard/components/pinboard-editor.component";

@Component({
  selector: 'page-editor-component-editor-page',
  imports: [
    AdminPageHeaderComponent,
    MatButton,
    MatIcon,
    ArticleEditorComponent,
    PageEditorComponentContainerFormComponent,
    RichMapEditorComponent,
    PinboardEditorComponent
  ],
  template: `
    <admin-page-header [title]="title()" />
    <div class="toolbar">
      <div class="button-container">
        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
      </div>
    </div>
    @switch (component().type) {
      @case ('container') {
        <div class="form-container">
          <component-container-form />
        </div>
      }
      @case ('hero-teaser') {
        <!-- need the page oz upload button => to something about later -->
        <div class="form-container">
        </div>
      }
      @case ('article') {
        <div class="form-container">
          <article-editor />
        </div>
      }
      @case ('rich-map') {
        <!-- option deprecated => use "map" instead -->
        <div class="form-container">
          <rich-map-editor (afterSave)="navigateToPageEditor()" />
        </div>
      }
      @case ('map') {
        <div class="form-container">
          <rich-map-editor (afterSave)="navigateToPageEditor()" />
        </div>
      }
      @case ('pinboard') {
        <div class="form-container">
          <pinboard-editor />
        </div>
      }
      @case ('text') {
        <!-- coming soon -->
        <p>text</p>
      }
      @case ('image') {
        <!-- coming soon -->
        <p>image</p>
      }
    }
  `,
})
export class PageEditorComponentEditorPageComponent {
  @HostBinding('class.page-editor-component-editor-page') cssClass = true;
  @HostBinding('class.admin-page') cssAdminPage = true;
  private pageEditorService = inject(PageEditorService)
  component = this.pageEditorService.component;
  title = computed(() => {
    switch (this.component().type) {
      case 'container':
        return 'Container Einstellungen';
      case 'article':
        return 'Artikel Einstellungen';
      case 'hero-teaser':
        return 'Hero Teaser Einstellungen';
      case 'map':
      case 'rich-map':
        return 'Karte Einstellungen';
      case 'pinboard':
        return 'Pinnwand Einstellungen';
      case 'text':
        return 'Text Einstellungen';
      case 'image':
        return 'Bild Einstellungen';
    }
    return 'Page Editor Component Editor [unknown component type]';
  });
  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }
}
