import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, OnInit, signal} from "@angular/core";
import {PinboardEntry, pinboardEntryFactory} from "../types";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {FontService} from "../../font/font.service";
import {ComponentConfig, componentConfigFactory} from "../../project";
import {MatButton} from "@angular/material/button";
import {MarkdownComponent} from "ngx-markdown";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {NavigationService} from "../../../services/navigation.service";
import {DeployService} from "../../../deploy/deploy.service";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'pinboard-entry-card',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatButton,
    RouterLink,
    MarkdownComponent,
  ],
  template: `
    <mat-card [style.background-color]="bgPinboardCardColor()">
      <mat-card-header style="flex-direction: column;">
        <div class="flex-row">
          <div class="pinboard-entry-card-title">{{title()}}</div>
          <div class="pinboard-entry-card-button">
            <button [routerLink]="detailLink()" mat-raised-button>Mehr...</button>
          </div>
        </div>
        <div class="flex-row gap meta-info">
          <div class="pinboard-entry-card-author-name flex">{{author()}}</div>
          <div class="pinboard-entry-card-author-name">{{created()}}</div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="pinboard-entry-card-content {{contentSizeClass()}}"
             [style.font-family]="fontValue()"><markdown [data]="shortDescription()" /></div>
      </mat-card-content>
    </mat-card>
  `
})
export class PinboardEntryCardComponent implements OnInit {
  @HostBinding('class.pinboard-entry-card') dssClass = true;
  component = input<ComponentConfig>(componentConfigFactory());
  entry = input<PinboardEntry>(pinboardEntryFactory());
  isDeployView = input(false);
  private fontService = inject(FontService);
  private pageEditorService = inject(PageEditorService);
  private deploy = inject(DeployService);
  private nav = inject(NavigationService);
  title = computed(() => this.entry().title);
  author = computed(() => this.entry().author_name);
  created = computed(() => this.entry().created);
  shortDescription = computed(() => this.entry().short_description);
  fontValue = computed(() => {
    const config = this.component().config;
    if(Object.hasOwn(config, 'font_key')) {
      const fontKey = config['font_key'];
      const selectedFont = this.fontService.fonts().find(f =>f.key === fontKey);
      if(selectedFont) {
        return selectedFont.value;
      }
    }
    return this.fontService.defaultFont().value;
  });

  bgPinboardCardColor = signal<string>('#FFFFFF');
  contentSizeClass = computed(() => {
    const config = this.component().config;
    if(Object.hasOwn(config, 'content_size_class')) {
      return ` ${config['content_size_class']} `;
    }
    return '';
  });

  detailLink = computed(() => {
    const entryId = this.entry().id;

    if(this.isDeployView()) {
      let pagePath = this.deploy.pagePath();
      pagePath = pagePath.length > 0 ? `${pagePath}/` : '';
      return this.nav.getSegments(`${pagePath}pinboard-entry-display/${entryId}`)();
    }

    const projectId = this.pageEditorService.project().id;
    const pageId = this.pageEditorService.page().id;
    return this.nav.getSegments(`admin/component-editor/${projectId}/${pageId}/pinboard-entry-display/${entryId}`)();
  });

  ngOnInit() {
    const config = this.component().config || {};
    if(Object.hasOwn(config, 'bg_color_pinboard_card')) {
      this.bgPinboardCardColor.set(config['bg_color_pinboard_card']);
    }
  }
}
