import {ChangeDetectionStrategy, Component, HostBinding, inject, signal} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {AuthService} from "../services/auth.service";
import {PaKeycloakService} from "../services/pa-keycloak.service";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'landing-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    RouterLink,
  ],
  template: `
    <img src="/img/logo.svg" alt="Logo: Polit@ktiv" height="120" width="234"/>
    @if (isLoggedIn()) {
      <div class="auth-process-content">
        <div>Eingeloggt als</div>
        <div>{{ loggedInName() }}</div>
        <div>{{ loggedInEmail() }}</div>
      </div>
    }

    @if (kcEnable()) {
      <div style="padding-top: 1rem;"></div>

      @if (kcIsLoggedIn()) {
        <button (click)="kcLogout()"
                mat-raised-button>Logout</button>
      } @else {
        <button (click)="kcLogin()"
                mat-raised-button>Login</button>
        <div style="padding-top: 1rem;"></div>
        <a [routerLink]="registerLink()">Registrieren</a>
        <div style="padding-top: 1rem;"></div>
        <a [routerLink]="resetLink()">Passwort vergessen?</a>
      }
    }
  `
})
export class AuthLandingPageComponent {
  @HostBinding('class.landing-page') cssClass = true;
  @HostBinding('class.auth-process-page') cssTypeClass = true;
  private nav = inject(NavigationService);
  private auth = inject(AuthService);
  private paKeycloakService = inject(PaKeycloakService);

  registerLink = this.nav.getSegments('auth/register');
  resetLink = this.nav.getSegments('auth/password-reset');

  kcEnable = signal(true);

  isLoggedIn = this.auth.isLoggedIn;
  loggedInName = this.auth.userName;
  loggedInEmail = this.auth.userEmail;
  kcIsLoggedIn = this.paKeycloakService.isLoggedIn;

  kcLogin() {
    this.auth.kcLogin();
  }

  kcLogout() {
    this.auth.kcLogout();
  }
}

