import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal} from "@angular/core";
import {DynamicNavigationNodeComponent} from "./dynamic-navigation-node.component";
import {PageEditorService} from "../page-editor/services/page-editor.service";
import {DeployService} from "../../deploy/deploy.service";
import {DynamicNavigationNode} from "./types";

@Component({
  selector: 'dynamic-navigation',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicNavigationNodeComponent,
  ],
  template: `
    @for (node of nodes(); track node.id) {
      @if (isNodeVisible(node)) {
        <dynamic-navigation-node [node]="node" [isDeployView]="isDeployView()"/>
      }
    }
<!--      <pre style="width: 100%;">-->
<!--        data: {{nodes() | json}}-->
<!--      </pre>-->
  `,
})
export class DynamicNavigationComponent {
  @HostBinding('class.dynamic-navigation') cssClass = true;
  pageEditorService = inject(PageEditorService);
  isDeployView = input(false);
  private deployService = inject(DeployService);
  nodes = computed(() => this.isDeployView()
    ? this.deployService.config().navigationNodes
    : this.pageEditorService.config().navigationNodes);

  isNodeVisible = (node: DynamicNavigationNode) => {
    if(this.isDeployView() && node?.deny_free_access) {
      return this.deployService.isUserActivated();
    }
    return true;
  }
}
