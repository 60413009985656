import {Route} from "@angular/router";
import {DeployLayoutComponent} from "./deploy-layout.component";
import {
  PinboardEntryDeployDisplayPageComponent
} from "../features/pinboard/components/pinboard-entry-deploy-display-page.component";
import {canReadPinboardEntry} from "../features/pinboard/services/pinboard-entry.service";
import {DeployDefaultPageComponent} from "./deploy-default-page.component";
import {canViewDeploy, canViewDeployPage, canViewDeployPageFirst} from "./guards";
import {guardSetDeployRouteTrue} from "./guards/guardSetDeployRouteTrue";
import {MapEntryDisplayPageComponent} from "../features/map/components/map-entry-display-page.component";
import {canReadMapEntry} from "../features/map/guards/canReadMapEntry";
import {MapEntryDeployDisplayPageComponent} from "../features/map/components/map-entry-deploy-display-page.component";

export const deployRoute: Route = {
  path: '', component: DeployLayoutComponent, canActivate: [canViewDeploy], children: [
    {
      path: 'pinboard-entry-display/:entry_id',
      component: PinboardEntryDeployDisplayPageComponent,
      canActivate: [guardSetDeployRouteTrue, canReadPinboardEntry]
    },
    {
      path: 'map-entry-display/:entry_id',
      component: MapEntryDeployDisplayPageComponent,
      canActivate: [guardSetDeployRouteTrue, canReadMapEntry]
    },
    {
      path: ':page', canActivate: [canViewDeployPage], children: [
        {
          path: 'pinboard-entry-display/:entry_id',
          component: PinboardEntryDeployDisplayPageComponent,
          canActivate: [canReadPinboardEntry]
        },
        {
          path: '', children: [
            {
              path: 'pinboard-entry-display/:entry_id',
              component: PinboardEntryDeployDisplayPageComponent,
              canActivate: [guardSetDeployRouteTrue, canReadPinboardEntry]
            },
            {
              path: 'map-entry-display/:entry_id',
              component: MapEntryDeployDisplayPageComponent,
              canActivate: [guardSetDeployRouteTrue, canReadMapEntry]
            },
            {path: '', component: DeployDefaultPageComponent}
          ]
        },
      ]
    },
    {path: '', component: DeployDefaultPageComponent, canActivate: [canViewDeployPageFirst]},
  ]
};
