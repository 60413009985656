import {inject, Injectable, signal} from "@angular/core";
import {KeycloakService} from "keycloak-angular";
import {AppService} from "../../../services/app.service";
import {KeycloakProfile} from "keycloak-js";
import {environment} from "../../../../../environments/environment";

const kcProfileFactory = (): KeycloakProfile => ({
  id: '',
  email: '',
});

@Injectable({providedIn: 'root'})
export class PaKeycloakService {
  private app = inject(AppService);
  public keycloakService = inject(KeycloakService);
  private profile = signal<KeycloakProfile>(kcProfileFactory());
  isLoggedIn = signal(false);
  token = signal('');
  // tokenRefreshInterval: any = null;
  //
  // startRefreshInterval() {
  //   this.tokenRefreshInterval = setInterval(async () => {
  //     try {
  //       const isTokenRefreshed = await this.keycloakService.updateToken(30); // 30 seconds
  //       if (isTokenRefreshed) {
  //         console.log('Token successfully refreshed');
  //         const token = await this.keycloakService.getToken();
  //         this.token.set(token);
  //       } else {
  //         console.log('Token is still valid');
  //       }
  //     } catch (error) {
  //       console.error('Failed to refresh token:', error);
  //       // Optional: You can redirect the user to the login page if token cannot be refreshed
  //       // this.keycloakService.login();
  //     }
  //   }, 10000); // Check every 10 seconds
  // }
  //
  // stopRefreshInterval() {
  //   if (this.tokenRefreshInterval) {
  //     clearInterval(this.tokenRefreshInterval);
  //     this.tokenRefreshInterval = null;
  //   }
  // }

  async init() {
    if (this.app.isBrowser()) {
      // console.log('kc inner start');
      await this.keycloakService.init({
        config: environment.keycloak.config,
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            `${window.location.origin}/silent-check-sso.html`,
        }
      });

      this.isLoggedIn.set(this.keycloakService.isLoggedIn());

      if(this.keycloakService.isLoggedIn()) {
        console.log('kc-user is logged in');
        // this.startRefreshInterval();

        const userProfile = await this.keycloakService.loadUserProfile();
        this.profile.set(userProfile);
        console.log('UserProfile:', userProfile);
        const token = await this.keycloakService.getToken();
        this.token.set(token);
        console.log('token:', token);

        // // Get user roles
        // const userRoles = this.keycloakService.getUserRoles();
        //
        // // Logging user data
        // console.log('UserRoles:', userRoles);

      } else {
        this.profile.set(kcProfileFactory());
        this.token.set('');
        console.log('kc-user is not logged in');
      }

      // console.log('kc inner end');
    }
    return true;
  }
}
