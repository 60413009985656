import {Route} from "@angular/router";
import {canActivateAdminView} from "../features/auth/services/auth.service";
import {AdminDashboardPageComponent} from "../features/dashboard";
import {AdminSettingsPageComponent} from "../features/settings/admin-settings-page.component";
import {AdminPinboardEntryListPageComponent} from "../features/pinboard/components/admin-pinboard-entry-list-page.component";
import {
  canEditPinboard, canReadPinboardEntry
} from "../features/pinboard/services/pinboard-entry.service";
import {AdminPinboardEntryDetailPageComponent} from "../features/pinboard/components/admin-pinboard-entry-detail-page.component";
import {AdminMapEntryListPageComponent} from "../features/map/components/admin-map-entry-list-page.component";
import {canActivateMapEntryList} from "../features/map/guards/canActivateMapEntryList";
import {AdminMapEntryDetailPageComponent} from "../features/map/components/admin-map-entry-detail-page.component";
import {
  AdminUserCommentDetailComponent,
  AdminUserCommentListPageComponent,
  canEditUserComment
} from "../features/user-comment";
import {AdminAssetListPageComponent} from "../features/asset";
import {AdminUserDetailPageComponent, AdminUserListPageComponent, canCreateUser, canEditUser} from "../features/user";
import {AdminUserRolePageComponent} from "../features/user/components/admin-user-role-page.component";
import {AdminClientListPageComponent} from "../features/client/admin-client-list-page.component";
import {AdminClientDetailPageComponent} from "../features/client/admin-client-detail-page.component";
import {canCreateClient, canEditClient} from "../features/client";
import {AdminProjectListPageComponent} from "../features/project/admin-project-list-page.component";
import {AdminProjectDetailPageComponent} from "../features/project/admin-project-detail-page.component";
import {AdminProjectTagPageComponent} from "../features/project/admin-project-tag-page.component";
import {PageEditorLayoutComponent} from "../features/page-editor/components/page-editor-layout.component";
import {DynamicNavigationEditorPageComponent} from "../features/navigation/dynamic-navigation-editor-page.component";
import {
  HeroTeaserEditorPageComponent
} from "../features/page-editor/components/hero-teaser/hero-teaser-editor-page.component";
import {canEditHeroTeaser} from "../features/page-editor/components/hero-teaser/hero-teaser.service";
import {canEditRichMap} from "../features/map/services/rich-map.service";
import {RichMapEditorPage} from "../features/map/components/rich-map-editor-page.component";
import {PinboardEditorPageComponent} from "../features/pinboard/components/pinboard-editor-page.component";
import {PinboardEntryDisplayPageComponent} from "../features/pinboard/components/pinboard-entry-display-page.component";
import {ArticleEditorPageComponent} from "../features/page-editor/components/article/article-editor-page.component";
import {canEditArticle} from "../features/page-editor/components/article/article-editor.service";
import {
  AdminDomainDetailPageComponent,
  AdminDomainListPageComponent,
  canCreateDomain,
  canEditDomain
} from "../features/domain";
import {DevTestPageComponent} from "../features/dev";
import {AdminLayoutComponent} from "./admin-layout.component";
import {AdminInfoPageComponent} from "./admin-info-page.component";
import {canPinboardEntryCommentListPage} from "../features/pinboard/guards/can-pinboard-entry-comment-list-page";
import {
  AdminPinboardEntryCommentListPageComponent
} from "../features/pinboard/components/admin-pinboard-entry-comment-list-page.component";
import {canPinboardEntryCommentDetailPage} from "../features/pinboard/guards/can-pinboard-entry-comment-detail-page";
import {
  AdminPinboardEntryCommentDetailPageComponent
} from "../features/pinboard/components/admin-pinboard-entry-comment-detail-page.component";
import {canAdminPinboardEntryListPage} from "../features/pinboard/guards/can-admin-pinboard-entry-list-page";
import {guardSetDeployRouteFalse} from "../deploy/guards/guardSetDeployRouteFalse";
import {canEditProject} from "../features/project/guards/canEditProject";
import {canCreateProject} from "../features/project/guards/canCreateProject";
import {canEditMapEntry} from "../features/map/guards/canEditMapEntry";
import {canReadMapEntry} from "../features/map/guards/canReadMapEntry";
import {MapEntryDisplayPageComponent} from "../features/map/components/map-entry-display-page.component";
import {canEditProjectPages} from "../features/page-editor/guards/canEditProjectPages";
import {canEditPinboardEntry} from "../features/pinboard/guards/canEditPinboardEntry";
import {
  AdminMapEntryCommentListPageComponent
} from "../features/map/components/admin-map-entry-comment-list-page.component";
import {canMapEntryCommentListPage} from "../features/map/guards/canMapEntryCommentListPage";
import {canMapEntryCommentDetailPage} from "../features/map/guards/canMapEntryCommentDetailPage";
import {
  PageEditorComponentContainerEditorPageComponent
} from "../features/page-editor/components/component-container/page-editor-component-container-editor-page.component";
import {
  canEditComponentContainer
} from "../features/page-editor/components/component-container/canEditComponentContainer";
import {
  AdminMapEntryCommentDetailPageComponent
} from "../features/map/components/admin-map-entry-comment-detail-page.component";
import {
  PageEditorComponentEditorPageComponent
} from "../features/page-editor/components/page-editor-component-editor-page.component";
import {canEditComponent} from "../features/page-editor/guards/canEditComponent";

export const adminRoute: Route = {
  path: 'admin', canActivate: [canActivateAdminView], component: AdminLayoutComponent, children: [
    {path: '', component: AdminDashboardPageComponent},
    {path: 'settings', component: AdminSettingsPageComponent},
    {
      path: 'pinboard',
      component: AdminPinboardEntryListPageComponent,
      canActivate: [canAdminPinboardEntryListPage]
    },
    {
      path: 'pinboard/:id',
      component: AdminPinboardEntryDetailPageComponent,
      canActivate: [canEditPinboardEntry]
    },
    {
      path: 'pinboard-entry/:entryId/comments',
      component: AdminPinboardEntryCommentListPageComponent,
      canActivate: [canPinboardEntryCommentListPage]
    },
    {
      path: 'pinboard-entry/:entryId/comment/:commentId',
      component: AdminPinboardEntryCommentDetailPageComponent,
      canActivate: [canPinboardEntryCommentDetailPage]
    },
    {
      path: 'map-entries',
      component: AdminMapEntryListPageComponent,
      canActivate: [canActivateMapEntryList]
    },
    {
      path: 'map-entry/:id',
      component: AdminMapEntryDetailPageComponent,
      canActivate: [canEditMapEntry]
    },
    {
      path: 'map-entry/:entryId/comments',
      component: AdminMapEntryCommentListPageComponent,
      canActivate: [canMapEntryCommentListPage]
    },
    {
      path: 'map-entry/:entryId/comment/:commentId',
      component: AdminMapEntryCommentDetailPageComponent,
      canActivate: [canMapEntryCommentDetailPage]
    },
    {path: 'comments', component: AdminUserCommentListPageComponent},
    {path: 'info', component: AdminInfoPageComponent},
    {path: 'assets', component: AdminAssetListPageComponent},
    {path: 'users', component: AdminUserListPageComponent},
    {path: 'user', component: AdminUserDetailPageComponent, canActivate: [canCreateUser]},
    {path: 'user/:id/roles', component: AdminUserRolePageComponent, canActivate: [canEditUser]},
    {path: 'user/:id', component: AdminUserDetailPageComponent, canActivate: [canEditUser]},
    {path: 'clients', component: AdminClientListPageComponent},
    {path: 'client', component: AdminClientDetailPageComponent, canActivate: [canCreateClient]},
    {path: 'client/:id', component: AdminClientDetailPageComponent, canActivate: [canEditClient]},
    {path: 'projects', component: AdminProjectListPageComponent},
    {path: 'project', component: AdminProjectDetailPageComponent, canActivate: [canCreateProject]},
    {path: 'project/:id', component: AdminProjectDetailPageComponent, canActivate: [canEditProject]},
    {path: 'project-tags/:id', component: AdminProjectTagPageComponent, canActivate: [canEditProject]},
    {
      path: 'page-editor/:project_id/:page_id',
      component: PageEditorLayoutComponent,
      canActivate: [canEditProjectPages]
    },
    {
      path: 'navigation-editor/:project_id/:page_id',
      component: DynamicNavigationEditorPageComponent,
      canActivate: [canEditProjectPages]
    },
    {
      path: 'component-editor/:project_id/:page_id', canActivate: [canEditProjectPages], children: [
        {
          path: 'hero-teaser/:component_id',
          component: HeroTeaserEditorPageComponent,
          canActivate: [canEditHeroTeaser]
        },
        {path: 'rich-map/:component_id', component: RichMapEditorPage, canActivate: [canEditRichMap]},
        {path: 'map/:component_id', component: RichMapEditorPage, canActivate: [canEditRichMap]},
        {
          path: 'pinboard/:component_id',
          component: PinboardEditorPageComponent,
          canActivate: [canEditPinboard]
        },
        {
          path: 'pinboard-entry-display/:entry_id',
          component: PinboardEntryDisplayPageComponent,
          canActivate: [guardSetDeployRouteFalse, canReadPinboardEntry]
        },
        {
          path: 'map-entry-display/:entry_id',
          component: MapEntryDisplayPageComponent,
          canActivate: [guardSetDeployRouteFalse, canReadMapEntry]
        },
        {path: 'article/:component_id', component: ArticleEditorPageComponent, canActivate: [canEditArticle]},
        {path: 'container/:component_id', component: PageEditorComponentContainerEditorPageComponent, canActivate: [canEditComponentContainer]},
        {path: ':component_id', component: PageEditorComponentEditorPageComponent, canActivate: [canEditComponent]},
      ]
    },
    {path: 'domains', component: AdminDomainListPageComponent},
    {path: 'domain', component: AdminDomainDetailPageComponent, canActivate: [canCreateDomain]},
    {path: 'domain/:id', component: AdminDomainDetailPageComponent, canActivate: [canEditDomain]},
    {path: 'user-comments', component: AdminUserCommentListPageComponent},
    {path: 'user-comment/:id', component: AdminUserCommentDetailComponent, canActivate: [canEditUserComment]},
    {path: 'dev', component: DevTestPageComponent},
  ]
};
