import {ChangeDetectionStrategy, Component, HostBinding, inject, signal} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {NotificationService} from "../../../services/notification.service";
import {AuthPasswordResetService} from "../services/auth-password-reset.service";
import {NavigationService} from "../../../services/navigation.service";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'auth-password-reset-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    RouterLink,
    MatButton,
  ],
  template: `
    <img src="/img/logo.svg" alt="Logo: Polit@ktiv" height="120" width="234"/>
<!--    <p>under construction...</p>-->
<!--    <div style="padding-top: 1rem;"></div>-->
<!--    <a [routerLink]="loginLink()">Zum Login</a>-->

<!--    <div class="auth-process-content dev-hide">-->
    <div class="auth-process-content">
      @if (processExecuted()) {

        <p><!-- password reset message message -->
          Passwort zurücksetzen eingeleitet.<br/>
          Eine Email mit dem Link für
          die Eingabe des neuen Passwort
          wurde an Ihre Email-Adresse versand.
        </p>

      } @else {
        <div class="form-container">
          <div class="form-title">Passwort zurücksetzen</div>
          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [formControl]="emailControl"/>
              @if (!emailControl.valid) {
                <mat-error>Valide Email-Adresse benötigt!</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="form-action-container">
            <button (click)="submit()" mat-raised-button>Passwortzurücksetzung per Mail anfordern</button>
          </div>
        </div>
      }
      <div style="padding-top: 1rem;"></div>
      <a [routerLink]="loginLink()">Zum Login</a>
    </div>
  `
})
export class AuthPasswordResetPageComponent {
  @HostBinding('class.auth-password-reset-page') cssClass = true;
  @HostBinding('class.auth-process-page') cssTypeClass = true;
  private service = inject(AuthPasswordResetService);
  private note = inject(NotificationService);
  private nav = inject(NavigationService);
  loginLink = this.nav.getSegments('auth');
  processExecuted = signal(false);
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  submit() {
    this.emailControl.markAllAsTouched();
    if(!this.emailControl.valid) {
      return;
    }

    this.service.passwordReset({
      email: `${this.emailControl.value}`,
    }).then(this.success).catch(this.fail);
  }

  private success = () => {
    this.note.note('Anfrage erfolgreich versand!');
    this.processExecuted.set(true);
  }

  private fail = () => {
    this.note.note('Anfrage fehlgeschlagen!');
  }
}
