import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";
import {AuthService} from "../features/auth/services/auth.service";
import {NavigationService} from "../services/navigation.service";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'auth-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    RouterLink,
    MatDivider,
    MatIconButton
  ],
  template: `
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @if (isLoggedIn()) {
        <button mat-menu-item>
          <mat-icon>person_outline</mat-icon>
          {{ loggedInName() }}
        </button>
        <mat-divider/>
        <button (click)="logout()" mat-menu-item>Logout</button>
      } @else {
        <button (click)="login()" mat-menu-item>
          <mat-icon>person_outline</mat-icon>
          Login
        </button>
        <button [routerLink]="registerLink()" mat-menu-item>
          <mat-icon>person_outline</mat-icon>
          Registrieren
        </button>
        <button [routerLink]="resetLink()" mat-menu-item>
          <mat-icon>person_outline</mat-icon>
          Password vergessen?
        </button>
      }
    </mat-menu>
  `,
})
export class AuthMenuComponent {
  @HostBinding('class.auth-menu') classAuthMenu = true;
  private nav = inject(NavigationService);
  auth = inject(AuthService);
  isLoggedIn = this.auth.isLoggedIn;
  loggedInName = this.auth.userName;
  registerLink = this.nav.getSegments('auth/register');
  resetLink = this.nav.getSegments('auth/password-reset');

  logout() {
    this.auth.kcLogout();
  }

  login() {
    this.auth.kcLogin();
  }
}
