import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../../services/navigation.service";
import {PinboardEntryCommentService} from "../services/pinboard-entry-comment.service";
import {AdminPinboardEntryCommentListComponent} from "./admin-pinboard-entry-comment-list.component";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ModerationStatus, ModerationStatusFilter} from "../../../types";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'admin-pinboard-entry-comment-list-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatIcon,
    AdminPinboardEntryCommentListComponent,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatIconButton,
    MatInput,
    MatSuffix,
  ],
  template: `
    <admin-page-header [title]="title()"/>
    <div class="toolbar">
      <div class="button-container flex">
        <button [routerLink]="link('admin/pinboard')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste der Beiträge
        </button>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [formControl]="moderationStatusControl">
            <mat-option value="">- alle -</mat-option>
            <mat-option value="pending">wartend</mat-option>
            <mat-option value="approved">freigegeben</mat-option>
            <mat-option value="rejected">abgelehnt</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="list-container flex-1">
      <admin-pinboard-entry-comment-list />
    </div>
  `
})
export class AdminPinboardEntryCommentListPageComponent implements OnInit {
  @HostBinding('class.admin-page') dssClass = true;
  private nav = inject(NavigationService);
  private service = inject(PinboardEntryCommentService);
  private entry = this.service.entry;
  link = this.nav.getSegments;
  title = computed(() => `Beitrag: ${this.entry().title}`);
  search = new FormControl(this.service.filter().search);
  moderationStatusControl = new FormControl<ModerationStatusFilter>(this.service.filter().moderationStatus);

  ngOnInit() {
    this.moderationStatusControl.valueChanges.subscribe(this.updateFilterModerationStatus);
  }

  private updateFilterModerationStatus = (moderationStatus: ModerationStatusFilter | null)=> {
    if (moderationStatus === null) {
      moderationStatus = '';
    }
    this.service.filterSetModerationStatus(moderationStatus);
    this.reload();
  }

  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.loadList();
  }
}
