import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {PinboardEntryDisplayComponent} from "./pinboard-entry-display.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {DeployService} from "../../../deploy/deploy.service";
import {NavigationService} from "../../../services/navigation.service";
import {RouterLink} from "@angular/router";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardEntryCommentService} from "../services/pinboard-entry-comment.service";
import {PinboardEntryCommentDisplayComponent} from "./pinboard-entry-comment-display.component";
import {PinboardEntryCommentFormComponent} from "./pinboard-entry-comment-form.component";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PinboardEntryDisplayComponent,
    MatButton,
    MatIcon,
    RouterLink,
    PinboardEntryCommentDisplayComponent,
    PinboardEntryCommentFormComponent
  ],
  template: `
    <div class="page-content-container">
      <div class="toolbar">
        <div class="button-container">
          <button [routerLink]="backLink()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            Zurück
          </button>
        </div>
      </div>
    </div>

    <div class="page-content-container">
      <pinboard-entry-display [entry]="entry()" />
    </div>

    <div class="page-content-container">
      <pinboard-entry-comment-form [entry]="entry()" (saved)="afterSave()" />
    </div>

    <div class="page-content-container flex-column gap"
         style="padding-top: 2rem;">
      @for (comment of comments(); track comment) {
        <pinboard-entry-comment-display [comment]="comment" />
      }
    </div>

  `
})
export class PinboardEntryDeployDisplayPageComponent implements OnInit {
  @HostBinding('class.pinboard-entry-display-page') classAdminPage = true;
  private deploy = inject(DeployService);
  private nav = inject(NavigationService);
  private service = inject(PinboardEntryService);
  private commentService = inject(PinboardEntryCommentService);
  entry = this.service.currentItem;
  backLink = computed(() => this.nav.getSegments(this.deploy.pagePath())());
  comments = this.commentService.items;

  afterSave() {
    this.commentService.loadList();
  }

  ngOnInit() {
    this.commentService.filterSetModerationStatus('public');
    this.commentService.filterSetPinboardEntryId(this.entry().id);
    this.commentService.filterSetClientId(this.entry().client_id);
    this.commentService.filterSetProjectId(this.entry().project_id);
    this.commentService.loadList();
  }
}
