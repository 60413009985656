import {ChangeDetectionStrategy, Component, HostBinding, inject, input, signal} from "@angular/core";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {RichMapEditorComponent} from "./rich-map-editor.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {AdminPageHeaderComponent} from "../../../admin/admin-page-header.component";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RichMapEditorComponent,
    MatButton,
    MatIcon
  ],
  template: `

    <admin-page-header title="Karteneinstellungen" />
    <div class="toolbar">
      <div class="button-container">
        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
      </div>
    </div>
    <div class="form-container">
      <rich-map-editor (afterSave)="navigateToPageEditor()" />
    </div>
<!--    <div>under construction...</div>-->
<!--    <pre>-->
<!--      component: {{component() | json}}-->
<!--    </pre>-->
  `,

})
export class RichMapEditorPage {
  @HostBinding('class.admin-page') dssClass = true;
  private pageEditorService = inject(PageEditorService);
  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }

  component = this.pageEditorService.component;
  // initValues = signal({
  //   // center: this.center(),
  //   // zoom: this.zoom()
  //   // center: [52.5200, 13.4050], // Berlin
  //   // zoom: 9
  //   // center: [48.73336, 9.31976], // Esslingen
  //   // zoom: 12
  // });
}
