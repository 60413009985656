import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal} from "@angular/core";
import {PinboardTagService} from "../services/pinboard-tag.service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {PinboardTag} from "../types";
import {ColorPickerModule} from "ngx-color-picker";
import {NgxColorsModule} from "ngx-colors";
import {MatIcon} from "@angular/material/icon";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FontService} from "../../font/font.service";
import {Font} from "../../font/types";
import {I18nTextInputComponent} from "../../i18n/i18n-text-input.component";

@Component({
  selector: 'pinboard-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatLabel,
    MatOption,
    MatSelect,
    MatButton,
    MatInput,
    ColorPickerModule,
    NgxColorsModule,
    MatIcon,
    MatIconButton,
    MatSuffix,
    MatAutocompleteModule,
    MatChipsModule,
    I18nTextInputComponent
  ],
  template: `
    <div class="form-row">
      <i18n-text-input [(value)]="title" label="Überschrift" />
    </div>

    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Tags auswählen</mat-label>
        <mat-select [formControl]="tagsControl" multiple>
          @for (tag of tags(); track tag) {
            <mat-option [value]="tag.id">{{ tag.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row">

      <mat-form-field appearance="outline">
        <mat-label>Farbe Hintergrund Pinwand</mat-label>
        <input [value]="bgPinboardColor()" matInput/>
        <button aria-label="palette"
                mat-icon-button
                ngx-colors-trigger
                [formControl]="bgPinboardControl"
                [style.color]="bgPinboardColor()"
                matSuffix>
          <mat-icon>palette</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Farbe Hintergrund Karte</mat-label>
        <input [value]="bgPinboardCardColor()" matInput/>
        <button aria-label="palette"
                mat-icon-button
                ngx-colors-trigger
                [formControl]="bgPinboardCardControl"
                [style.color]="bgPinboardCardColor()"
                matSuffix>
          <mat-icon>palette</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Schriftart der Kurzbeschreibung</mat-label>
        <mat-select [formControl]="fontControl" >
          @for (font of fonts(); track font.key) {
            <mat-option [value]="font">{{ font.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div [style.font-family]="selectedFontValue()" style="line-height: 3rem;">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      </div>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Kartengröße</mat-label>
        <mat-select [formControl]="contentSizeControl" >
          <mat-option value="size-3">klein</mat-option>
          <mat-option value="size-5">mittel</mat-option>
          <mat-option value="size-10">groß</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button (click)="save()" mat-button>Übernehmen</button>
    </div>
  `
})
export class PinboardEditorComponent implements OnInit {
  @HostBinding('class.admin-page') dssClass = true;
  private pinboardTagService = inject(PinboardTagService);
  private pageEditorService = inject(PageEditorService);
  private fontService = inject(FontService);
  component = this.pageEditorService.component;

  title = signal('{}');
  tags = this.pinboardTagService.items;
  selectedTags = signal<Array<PinboardTag>>([]);
  tagsControl = new FormControl<Array<string>>([]);
  bgPinboardColor = signal<string>('#FFFFFF');
  bgPinboardControl = new FormControl<string>(this.bgPinboardColor());
  bgPinboardCardColor = signal<string>('#FFFFFF');
  bgPinboardCardControl = new FormControl<string>(this.bgPinboardCardColor());

  fonts = this.fontService.fonts;
  fontControl = new FormControl<Font>(this.fontService.defaultFont());
  selectedFont = signal(this.fontService.defaultFont());
  selectedFontValue = computed(() => this.selectedFont().value);
  contentSizeControl = new FormControl('size-3');

  save() {
    const component = this.component();
    const updatedComponent = {
      ...component,
      config: {
        ...component.config,
        title: this.title(),
        tag_ids: this.tagsControl.value,
        bg_color_pinboard: this.bgPinboardControl.value,
        bg_color_pinboard_card: this.bgPinboardCardControl.value,
        font_key: this.selectedFont().key,
        content_size_class: this.contentSizeControl.value
      }
    }
    this.pageEditorService.updateComponent(updatedComponent);
    this.pageEditorService.navigateToInitializedProject();
  }

  ngOnInit() {
    const config = this.component().config || {};

    if(Object.hasOwn(config, 'title')) {
      this.title.set(config['title']);
    }

    if(Object.hasOwn(config, 'tag_ids')) {
      this.selectedTags.set(this.tags().filter(tag => config['tag_ids'].includes(tag.id)));
      this.tagsControl.setValue(config['tag_ids']);
    }
    if(Object.hasOwn(config, 'bg_color_pinboard')) {
      this.bgPinboardControl.setValue(config['bg_color_pinboard']);
      this.bgPinboardColor.set(config['bg_color_pinboard']);
    }
    if(Object.hasOwn(config, 'bg_color_pinboard_card')) {
      this.bgPinboardCardControl.setValue(config['bg_color_pinboard_card']);
      this.bgPinboardCardColor.set(config['bg_color_pinboard_card']);
    }
    if(Object.hasOwn(config, 'font_key')) {
      console.log('font_key', config['font_key'])
      const selectedFont = this.fontService.fonts().find(f => f.key === config['font_key']);
      if(selectedFont) {
        this.fontControl.setValue(selectedFont);
        this.selectedFont.set(selectedFont);
      }
    }

    if(Object.hasOwn(config, 'content_size_class')) {
      this.contentSizeControl.setValue(config['content_size_class']);
    }

    this.bgPinboardControl.valueChanges.subscribe(value => this.bgPinboardColor.set(`${value}`));
    this.bgPinboardCardControl.valueChanges.subscribe(value => this.bgPinboardCardColor.set(`${value}`));
    this.fontControl.valueChanges.subscribe(value => console.log({value}));
    this.fontControl.valueChanges.subscribe(value => this.selectedFont.set(value as Font));
  }
}
