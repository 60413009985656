import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal} from "@angular/core";
import {ProjectService} from "../../project/services/project.service";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AdminService} from "../../../admin/admin.service";
import {NotificationService} from "../../../services/notification.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatChip, MatChipSet} from "@angular/material/chips";
import {MapTagService} from "../services/map-tag.servie";
import {MapTag, mapTagFactory} from "../types";
import {MatIcon} from "@angular/material/icon";
import {NgxColorsModule} from "ngx-colors";

@Component({
  selector: 'admin-map-tag-admin',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatChipSet,
    MatChip,
    MatError,
    MatIcon,
    MatIconButton,
    MatSuffix,
    NgxColorsModule
  ],
  template: `
    <p class="mat-headline-large">Map TAGs</p>
    <div class="tag-form">
      <div class="flex-row flex-wrap gap">
        <mat-form-field appearance="outline">
          <mat-label>TAG name</mat-label>
          <input [formControl]="tagControl" matInput />
          <mat-error>Kann nicht leer sein.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="symbol-control">
          <mat-label>TAG Symbol</mat-label>
          <input [formControl]="symbolControl" matInput />
          <mat-error>Soll ein Zeichen sein.</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Icon Farbe</mat-label>
        <input [value]="iconColor()" matInput/>
        <button aria-label="palette"
                mat-icon-button
                ngx-colors-trigger
                [formControl]="iconColorControl"
                [style.color]="iconColor()"
                matSuffix>
          <mat-icon>palette</mat-icon>
        </button>
      </mat-form-field>

      <div class="button-container">
        <button (click)="saveTag()" mat-raised-button>Erstellen</button>
      </div>
    </div>
    <div class="tag-list">
      <mat-chip-set>
        @for (tag of tags(); track tag) {
          <mat-chip>
            <div class="custom-map-marker-wrapper" style="
              position: relative;
              display: inline-block;
              margin-right: 1.5rem;
              top: -0.7rem;
              left: -0.2rem;
">
              <div class="custom-map-marker" style="position: relative;">
                <div class="marker-pin" style="background-color: {{tagColor(tag)}};"></div>
                <i style="
                  color: {{tagColor(tag)}};
                  top: -0.9rem;
                  left: -0.5rem;
                ">{{tagSymbol(tag)}}</i>
              </div>
            </div>
<!--            <div style="display: inline-block; height: 1rem; width: 1rem;"-->
<!--                 [style.background-color]="tagColor(tag)"></div>-->
            {{tag.name}}
<!--            [{{tagSymbol(tag)}}]-->

          </mat-chip>

        }
      </mat-chip-set>
    </div>
  `
})
export class AdminMapTagAdminComponent implements OnInit {
  @HostBinding('class.admin-map-tag-admin') cssClass = true;
  private note = inject(NotificationService);
  private adminService = inject(AdminService);
  private projectService = inject(ProjectService);
  private mapTagService = inject(MapTagService);
  private formBuilder = inject(FormBuilder);

  project = this.projectService.currentItem;
  tags = this.mapTagService.items;
  tagControl = new FormControl('', [Validators.required]);
  symbolControl = new FormControl('', [Validators.required, Validators.maxLength(1)]);
  private form = this.formBuilder.group({
    name: this.tagControl,
    symbol: this.symbolControl,
  });

  iconColor = signal<string>('#000000');
  iconColorControl = new FormControl<string>(this.iconColor());

  ngOnInit() {
    this.mapTagService.filterSetClientId(this.adminService.clientId());
    this.mapTagService.filterSetProjectId(this.project().id);
    this.mapTagService.loadList();

    this.iconColorControl.valueChanges.subscribe(value => this.iconColor.set(`${value}`));
  }

  saveTag() {
    const value = `${this.tagControl.value}`;
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      console.log('form invalid', this.form.errors);
      return;
    }

    const newTag: MapTag =  {
      ...mapTagFactory(),
      client_id: this.adminService.clientId(),
      project_id: this.project().id,
      name: value,
      config: JSON.stringify({
        symbol: `${this.symbolControl.value}`,
        color: `${this.iconColorControl.value}`,
      }),
    };
    console.log('save tag', newTag);

    this.mapTagService.save(newTag)
      .then(this.saveTagSuccess)
      .catch(this.saveTagFail);
  }

  saveTagSuccess = () => {
    this.note.note('Gespeichert!');
    this.reset();
    this.mapTagService.loadList();
  }

  saveTagFail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  tagSymbol(tag: MapTag): string {
    return JSON.parse(tag.config)?.symbol;
  }
  tagColor(tag: MapTag): string {
    const color = JSON.parse(tag.config)?.color;
    if(color && color.length > 0) {
      return color;
    }
    return '#000000';
  }

  reset() {
    this.symbolControl.reset();
    this.tagControl.reset();
    this.iconColorControl.reset();
    this.iconColor.set('#000000');
  }
}
