import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../services/notification.service";
import {ModerationStatus} from "../../../types";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MapEntryCommentService} from "../services/map-entry-comment.service";

@Component({
  selector: 'admin-map-entry-comment-detail-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatInput,
    MatButton,
  ],
  template: `
    <div class="form-row">
      <mat-form-field  appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="moderationStatusControl">
          <mat-option value="pending">Wartend</mat-option>
          <mat-option value="approved">Freigegeben</mat-option>
          <mat-option value="rejected">Abgelehnt</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input [formControl]="authorNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Artikel-Text</mat-label>
        <textarea [formControl]="contentControl" rows="6" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button (click)="save()" mat-raised-button>Speichern</button>
      <button (click)="delete()" mat-raised-button>Löschen</button>

    </div>
  `
})
export class AdminMapEntryCommentDetailFormComponent implements OnInit {
  @HostBinding('class.admin-map-entry-comment-detail-form') cssClass = true;
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private service = inject(MapEntryCommentService);
  private entry = this.service.entry;
  item = this.service.currentItem;

  authorNameControl = new FormControl({value:'', disabled: true});
  authorEmailControl = new FormControl({value:'', disabled: true});
  contentControl = new FormControl('');
  moderationStatusControl = new FormControl('pending');

  save() {
    const field = new FormControl({ value: '', disabled: true });
    field.setValue('foo');


    let mValue = `${this.moderationStatusControl.value}`;
    if(!['pending','rejected','approved'].includes(mValue)) {
      mValue = 'pending'
    }

    const newItem = {
      ...this.item(),
      content: this.contentControl.value || '',
      moderation_status: (mValue) as ModerationStatus,
    };

    this.service.save(newItem)
      .then(this.success)
      .catch(this.fail);
  }

  success = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate(`admin/map-entry/${this.entry().id}/comments`);
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  delete = () => {
    this.note.confirm("Kommentar zum Eintrag Löschen?", () => {
      this.service.deleteById(this.item().id)
        .then(this.deleteSuccess)
        .catch(this.deleteFail);
    });
  }

  deleteSuccess = () => {
    this.note.note('Gelöscht!');
    this.nav.navigate(`admin/map-entry/${this.entry().id}/comments`);
  }

  deleteFail = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }

  ngOnInit() {
    const item = this.item();
    this.authorNameControl.setValue(item.author_name, {emitEvent: false});
    this.authorEmailControl.setValue(item.author_email, {emitEvent: false});
    this.contentControl.setValue(item.content, {emitEvent: false});
    this.moderationStatusControl.setValue(item.moderation_status, {emitEvent: false});
  }
}
