import {ChangeDetectionStrategy, Component, computed, HostBinding, input, signal} from "@angular/core";
import {ContainerColumnConfig} from "../select-component-dialog/types";
import {ArticleComponent} from "../article/article.component";
import {HeroTeaserComponent} from "../hero-teaser/hero-teaser.component";
import {PinboardComponent} from "../../../pinboard/components/pinboard.component";
import {PinboardEntryFormComponent} from "../../../pinboard/components/pinboard-entry-form.component";
import {RichMapContainerComponent} from "../../../map/components/rich-map-container.component";

@Component({
  selector: 'deploy-component-container-column',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ArticleComponent,
    HeroTeaserComponent,
    PinboardComponent,
    PinboardEntryFormComponent,
    RichMapContainerComponent
  ],
  template: `
    @for (component of components(); track component) {
      @switch (component.type) {
        @case ('hero-teaser') {
          <div class="page-content-container component-wrapper">
            <hero-teaser [component]="component"
                         [isDeployView]="isDeployView()"/>
          </div>
        }
        @case ('rich-map') {
          <div class="page-content-container component-wrapper">
            <rich-map-container [component]="component"
                                [isDeployView]="isDeployView()"/>
          </div>
        }
        @case ('map') {
          <div class="page-content-container component-wrapper">
            <rich-map-container [component]="component"
                                [isDeployView]="isDeployView()"/>
          </div>
        }
        @case ('pinboard') {
          <div class="page-content-container component-wrapper">
            <pinboard [component]="component"
                      [isDeployView]="isDeployView()"
                      [clientId]="clientId()"
                      [projectId]="projectId()"/>
            <pinboard-entry-form [pinboardComponent]="component"
                                 [isDeployView]="isDeployView()"
                                 [clientId]="clientId()"
                                 [projectId]="projectId()"/>
          </div>
        }
        @case ('article') {
          <div class="page-content-container">
            <article-component [component]="component"
                               [isDeployView]="isDeployView()"/>
          </div>
        }
      }
    }
  `,
})
export class DeployComponentContainerColumnComponent {
  @HostBinding('class.page-editor-component-container-column') cssClass = true;
  @HostBinding('class.component-container-column') containerClass = true;
  column = input.required<ContainerColumnConfig>();
  clientId = input.required<string>();
  projectId = input.required<string>();
  components = computed(() => this.column().components);
  isDeployView = signal(true);
}
