import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {ProjectService} from "./services/project.service";
import {MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {Project} from "./types";
import {PageEditorService} from "../page-editor/services/page-editor.service";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-admin-project-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTableModule,
    MatIcon,
    RouterLink,
    MatButton,
  ],
  template: `
    <table mat-table [dataSource]="items()">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel</th>
        <td mat-cell *matCellDef="let item"> {{ item.title }}</td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div class="flex-row">
            <button (click)="deploy(item)" mat-button>Deploy</button>
            <button mat-button [routerLink]="tagLink(item.id)()">
              <mat-icon>bookmark</mat-icon>
              TAGs
            </button>
            <button mat-button (click)="navigateToPageEditor(item)">
              <mat-icon>edit</mat-icon>
              Editor
            </button>
            <button mat-button [routerLink]="detailLink(item.id)()">
              <mat-icon>search</mat-icon>
              Details
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `,
})
export class AdminProjectListComponent {
  private service = inject(ProjectService);
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private pageEditor = inject(PageEditorService);
  detailLink = (id: string) => this.nav.getSegments(`admin/project/${id}`);
  tagLink = (id: string) => this.nav.getSegments(`admin/project-tags/${id}`);
  items = this.service.items;
  displayedColumns: string[] = ['title', 'id'];

  navigateToPageEditor(project: Project) {
    this.pageEditor.initProject(project);
    this.pageEditor.navigateToInitializedProject();
  }

  deploy(item: Project) {
    this.note.confirm(
      'Deploy ausführen?',
      () => this.service.deploy(item.id)
        .then(this.deploySuccess)
        .catch(this.deployFailure)
    );
  }

  deploySuccess = () => {
    this.note.note('Deployed!');
  }

  deployFailure = () => {
    this.note.note('Deploy fehlgeschlagen!');
  }
}
