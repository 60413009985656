import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from '@angular/core';
import {ClientService} from "./client.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";
import {AuthService} from "../auth/services/auth.service";

@Component({
  selector: 'admin-client-form',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="nameControl" [disabled]="isDisabled()"/>
          @if (!nameControl.valid) {
            <mat-error>Name mit nim 6 Zeichen benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
    </div>

    @if (!isDisabled()) {
      <div class="flex flex-row overflow-clip button-container">
        <div class="flex-grow"></div>
          <div>
            <button mat-raised-button
                    (click)="submit()"
                    color="primary">Speichern
            </button>
            @if (!isNew()) {
              <button mat-raised-button
                      (click)="onDelete()"
                      color="primary">Löschen
              </button>
            }
          </div>
      </div>
    }
  `,
})
export class AdminClientFormComponent implements OnInit {
  @HostBinding('class.form-container') classFormContainer = true;
  private nav = inject(NavigationService);
  private snack = inject(MatSnackBar);
  private note = inject(NotificationService);
  private service = inject(ClientService);
  auth = inject(AuthService);
  item = this.service.currentItem;
  isNew = computed(() => this.item().id.length < 1);
  isDefault = computed(() => this.item().name === 'default');
  isDisabled = computed(() => this.isDefault() || !this.auth.isSystemAdmin());

  public nameControl = new FormControl(this.item().name,[
    Validators.required,
    Validators.minLength(6)
  ]);
  private form = new FormGroup({name: this.nameControl});
  public submit() {
    if(!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.nameControl.value === 'default') {
      this.note.note('Bitte anderen Namen wählen');
      return;
    }

    this.service.save({ ...this.item(), name: this.nameControl.value as string})
      .then(this.success)
      .catch(this.fail);
  }

  private success = () => {
    this.snack.open('Speichern erfolgreich!', '', {duration: 2000});
    this.nav.navigate('admin/clients');
  }

  private fail = () => {
    this.snack.open('Speichern fehlgeschlagen!', '', {duration: 2000});
  }

  onDelete() {
    this.note.confirm('Löschen?', this.delete);
  }

  delete = () => {
    this.service.deleteById(this.item().id)
      .then(this.deleteSuccess)
      .catch(this.deleteFail);
  }

  deleteSuccess = ()  => {
    this.note.note('Gelöscht!');
    this.nav.navigate('admin/clients');
  }

  deleteFail = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }

  ngOnInit() {
    if(this.isDefault()) {
      this.nameControl.disable();
    }
  }
}
