import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {UserCommentService} from "./user-comment.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-comment-list-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    FormsModule,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule
  ],
  template: `
    <admin-page-header title="Kommentarverwaltung"/>
    <div class="toolbar">
      <div class="flex"></div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="list-container">
      List here
    </div>
  `,
})
export class AdminUserCommentListPageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private service = inject(UserCommentService);
  search = new FormControl(this.service.filter$().search);
  reload = () => {
    this.service.filter$.update(
      value => ({...value, search: `${this.search.value}`})
    );
    this.service.loadList();
  }
  ngOnInit() {
    this.service.loadList();
  }
}
