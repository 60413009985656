import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";
import {MapEntryCommentService} from "../services/map-entry-comment.service";

export const canMapEntryCommentDetailPage: CanActivateFn =  async (route, state) => {
  const entryService = inject(MapEntryService);
  const commentService = inject(MapEntryCommentService);
  const entryId = `${route.paramMap.get('entryId')}`;
  const commentId = `${route.paramMap.get('commentId')}`;
  const entry = await entryService.fetchItem(entryId);
  commentService.setEntry(entry);
  const item = await commentService.fetchItem(commentId);
  commentService.currentItem.set(item);
  return true;
}
