import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {SelectComponentDialogData, SelectComponentDialogResult} from "./types";

@Component({
  selector: 'select-component-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatListModule,
  ],
  template: `
    <h2 mat-dialog-title>Komponente hinzufügen</h2>
    <mat-dialog-content>

      <mat-list>
        @if(!data.denyContainer) {
          <mat-list-item><button (click)="addComponent('container')" mat-button>Container</button></mat-list-item>
        }
        <mat-list-item><button (click)="addComponent('hero-teaser')" mat-button>Hero Teaser</button></mat-list-item>
        <mat-list-item><button (click)="addComponent('article')" mat-button>Artikel</button></mat-list-item>
        <mat-list-item><button (click)="addComponent('pinboard')" mat-button>Pinnwand</button></mat-list-item>
        <mat-list-item><button (click)="addComponent('map')" mat-button>Map</button></mat-list-item>
      </mat-list>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button (click)="close()" mat-button>Abbrechen</button>
    </mat-dialog-actions>
  `
})
export class SelectComponentDialogComponent {
  @HostBinding('class.select-component-dialog') cssClass = true;
  private dialogRef = inject(MatDialogRef<SelectComponentDialogComponent>);
  data = inject<SelectComponentDialogData>(MAT_DIALOG_DATA);

  close() {
    this.dialogRef.close({
      containerId: this.data.containerId,
      columnId: this.data.columnId,
      type: '',
    } as SelectComponentDialogResult);
  }

  addComponent(type: string) {
    this.dialogRef.close({
      containerId: this.data.containerId,
      columnId: this.data.columnId,
      type,
    } as SelectComponentDialogResult);
  }
}
