import {ChangeDetectionStrategy, Component, HostBinding, inject, input, OnInit, output} from "@angular/core";
import {AdminAssetListComponent, Asset, AssetService} from "../../../asset";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ComponentConfig} from "../../../project";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {PageEditorService} from "../../services/page-editor.service";
import {AdminPageHeaderComponent} from "../../../../admin/admin-page-header.component";
import {AdminService} from "../../../../admin/admin.service";

@Component({
  selector: 'component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    AdminAssetListComponent,
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule
  ],
  template: `
    <admin-page-header title="Hero Teaser bearbeiten" />
    <div class="toolbar">

      <div class="flex button-container">
        <input #uploadField hidden="hidden" type="file"
               onclick="this.value=null"
               (change)="onFileSelected($event)" [accept]="accept"/>

        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
        <button (click)="uploadField.click()" mat-button>
          <mat-icon>upload</mat-icon>
          Upload (max 5MB)
        </button>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>

    <div class="list-container">
      <admin-asset-list (assetSelected)="assetSelectHandler($event)"
                        [selectable]="true"
                        [selectType]="'images'" />
    </div>
  `
})
export class HeroTeaserEditorPageComponent implements OnInit {
  @HostBinding('class.hero-teaser-editor') cssCLass = true;
  @HostBinding('class.admin-page') classAdminPage = true;
  private service = inject(AssetService);
  private pageEditorService = inject(PageEditorService);
  private adminService = inject(AdminService);
  component = this.pageEditorService.component;

  accept = this.service.assetTypesAccepted;

  public search = new FormControl(
    this.service.filter().search
  );

  applied = output<ComponentConfig>()

  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.fetchList();
  }

  onFileSelected(event: any) {
    console.log('onFileSelected', event);
    const file:File = event.target.files[0];
    if (file) {
      this.service.upload(file, this.adminService.clientId()).then(asset => {
        this.reload();
      });
    }
  }

  assetSelectHandler(asset: Asset) {
    console.log('assetSelectHandler', asset);
    const component = this.component();
    this.pageEditorService.updateComponent({
      ...component,
      config: {
        ...component.config,
        asset
      }
    });
    this.pageEditorService.navigateToInitializedProject()
  }

  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }

  ngOnInit() {
    this.service.filterSetClientId(this.adminService.clientId());
    this.service.fetchList();
  }
}
