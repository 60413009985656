import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding, inject,
  input,
  model,
  OnInit,
  signal
} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {HashTable} from "../../types";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {I18nService} from "./i18n.service";

@Component({
  selector: 'i18n-text-input',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [],
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    MatButtonToggleGroup,
    MatButtonToggle,
  ],
  template: `
    <mat-form-field appearance="outline">
      <mat-label>{{ label() }}</mat-label>
      <input matInput [formControl]="formControl"/>
      @if(hasErrorMessage() && !formControl.valid && formControl.touched) {
        <mat-error>{{errorMessage()}}</mat-error>
      }
    </mat-form-field>
    <mat-button-toggle-group [formControl]="langControl"
                             [hideSingleSelectionIndicator]="true">
      @for (lang of langList(); track lang) {
        <mat-button-toggle [value]="lang">{{ lang }}</mat-button-toggle>
      }
    </mat-button-toggle-group>
  `
})
export class I18nTextInputComponent implements OnInit {
  @HostBinding('class.i18n-input') cssCLass = true;
  required = input<boolean>(false);
  errorMessage = input<string>('');
  hasErrorMessage = computed(() => this.errorMessage().length > 0);
  label = input<string>();
  value = model<string>();
  i18n = inject(I18nService);
  langList = computed(() => Object.keys(this.i18n.locales()));
  lang = signal(this.i18n.locale().code);
  valueObject = computed<HashTable<string>>(() => JSON.parse(`${this.value()}`) || {});
  formControl = new FormControl('');
  langControl = new FormControl(this.lang());

  switchLang(lang: string): void {
    this.lang.set(lang);
    this.formControl.setValue(this.valueObject()?.[lang] || '', {emitEvent: false});
  }

  ngOnInit() {
    this.formControl.setValue(
      this.valueObject()?.[this.lang()] || '',
      {emitEvent: false}
    );
    this.formControl.valueChanges.subscribe(value => {
      this.value.update(() => JSON.stringify({
        ...this.valueObject(),
        [this.lang()]: `${value}`.trim()
      }));
    });
    this.langControl.valueChanges
      .subscribe(lang => this.switchLang(`${lang}`));

    if(this.required()) {
      this.formControl.setValidators([Validators.required]);
    }
  }
}
