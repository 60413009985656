import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {RouterLink} from "@angular/router";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardEntry} from "../types";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'admin-pinboard-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterLink,
  ],
  template: `
    <table mat-table [dataSource]="items()">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel</th>
        <td mat-cell *matCellDef="let item"> {{ item.title }}</td>
      </ng-container>

      <ng-container matColumnDef="moderation_status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let item">{{ moderationState(item) }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div class="flex-row">
            <button mat-button
                    [routerLink]="commentsLink(item.id)()">
              <mat-icon>search</mat-icon>
              Kommentare
            </button>
            <button mat-button
                    [routerLink]="detailLink(item.id)()">
              <mat-icon>search</mat-icon>
              Details
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminPinboardEntryListComponent {
  private nav = inject(NavigationService);
  detailLink = (id: string) => this.nav.getSegments(`admin/pinboard/${id}`);
  commentsLink = (id: string) => this.nav.getSegments(`admin/pinboard-entry/${id}/comments`);
  private service = inject(PinboardEntryService);
  public items = this.service.items;
  public displayedColumns: string[] = ['title', 'moderation_status', 'id'];

  moderationState(item: PinboardEntry): string {
    if (item.moderation_status === 'approved') {
      return 'Freigegeben';
    } else if (item.moderation_status === 'rejected') {
      return 'Abgelehnt';
    } else {
      return 'Wartend';
    }
  }
}
