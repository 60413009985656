import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "../services/map-entry.service";
import {MapEntryCommentService} from "../services/map-entry-comment.service";

export const canMapEntryCommentListPage: CanActivateFn =  async (route, state) => {
  const entryService = inject(MapEntryService);
  const commentService = inject(MapEntryCommentService);
  const entryId = `${route.paramMap.get('entryId')}`;
  const entry = await entryService.fetchItem(entryId);
  commentService.setEntry(entry);
  await commentService.loadList();
  return true;
}
