import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {ProjectService} from "./services/project.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {AdminProjectListComponent} from "./admin-project-list.component";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'app-project-list-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    FormsModule,
    MatFormField,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    AdminProjectListComponent,
    RouterLink,
    MatButton
  ],
  template: `
    <admin-page-header title="Diskussionskreise"/>

    <div class="toolbar">
      <div class="flex button-container">
        <button [routerLink]="link('admin/project')()" mat-button>
          <mat-icon>add</mat-icon>
          Diskussionskreis Erstellen
        </button>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>

    <div class="list-container">
      <app-admin-project-list/>
    </div>
  `,
})
export class AdminProjectListPageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private service = inject(ProjectService);
  private nav = inject(NavigationService);
  private adminService = inject(AdminService);
  link = this.nav.getSegments;
  search = new FormControl(this.service.filter().search);
  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.loadList();
  }

  ngOnInit() {
    this.service.filterSetClientId(this.adminService.clientId());
    this.service.loadList();
  }
}
