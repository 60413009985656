import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {PageEditorService} from "../services/page-editor.service";
import {MatButtonModule} from "@angular/material/button";
import {DynamicSiteHeaderComponent} from "../../navigation/dynamic-site-header.component";
import {PageEditorComponentListComponent} from "./page-editor-component-list.component";
import {NavigationService} from "../../../services/navigation.service";
import {NotificationService} from "../../../services/notification.service";
import {ProjectService} from "../../project/services/project.service";
import {DeployFooterComponent} from "../../../deploy/deploy-footer.component";
import {SelectDialogService} from "./select-component-dialog/select-dialog.service";
import {MatDivider} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {SelectComponentDialogResult} from "./select-component-dialog/types";
import {componentConfigFactory} from "../../project";

@Component({
  selector: 'page-editor-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    DynamicSiteHeaderComponent,
    PageEditorComponentListComponent,
    DeployFooterComponent,
    MatDivider,
    MatButtonModule,
    MatMenuModule,
    MatIcon,
  ],
  template: `
    <dynamic-site-header/>

    <!-- page menu -->
    <div class="page-editor-component-menu-button-container">
      <button [matMenuTriggerFor]="menu" mat-icon-button><mat-icon>settings</mat-icon></button>
      <mat-menu #menu="matMenu" class="mat-menu-fix">
        <div class="no-hover" mat-menu-item>Domain: {{domain()}}</div>
        <mat-divider />
        <div (click)="backToList()" mat-menu-item><mat-icon>arrow_back</mat-icon> Zurück</div>
        <mat-divider />
        <button (click)="editNavigation()" mat-menu-item>Navigation bearbeiten</button>
        <button (click)="openComponentSelector()" mat-menu-item>Komponente hinzufügen</button>
        <mat-divider />
        <button (click)="save()" mat-menu-item>Speichern</button>
      </mat-menu>
    </div>
    <!-- page menu ~ end -->

    <!-- component list ~ end -->
    <div class="page-content-container">
      <page-editor-component-list [components]="components()"/>
    </div>
    <!-- component list ~ end -->

    <deploy-footer/>
  `
})
export class PageEditorLayoutComponent {
  @HostBinding('class.page-editor-layout') cssCLass = true;
  private service = inject(PageEditorService);
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private projectService = inject(ProjectService);
  private selectDialogService = inject(SelectDialogService);
  domain = computed(() => this.projectService.currentItem().domain);
  components= computed(() => this.service.page().components);

  save() {
    this.service.save()
      .then(this.saveSuccess)
      .catch(this.saveFailure);
  }

  saveSuccess = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate('admin/projects');
  }

  saveFailure = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  editNavigation() {
    this.service.navigateToNavigationEditor();
  }

  openComponentSelector() {
    this.selectDialogService
      .open({containerId: '', columnId: '', denyContainer: false})
      .then(this.afterComponentSelect);
  }

  afterComponentSelect = (result: SelectComponentDialogResult) => {
    console.log('afterComponentSelect', result);
    switch (result.type) {
      case 'hero-teaser':
      case 'article':
      case 'pinboard':
      case 'map':
      case 'container':
        this.service.addComponent({
          ...componentConfigFactory(),
          type: result.type,
        }, result.containerId, result.columnId);
      break;
    }
  }

  backToList() {
    this.note.confirm('Editor verlassen?', () => this.nav.navigate('admin/projects'));
  }
}
