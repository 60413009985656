import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {PageEditorService} from "../../services/page-editor.service";

export const canEditComponentContainer: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const component = pageEditorService.findComponentById(`${route.paramMap.get('component_id')}`);

  if(!component) {
    console.log('component id not found');
    return false;
  }

  if(component.type !== 'container') {
    console.log('invalid component type [expected: container]', component);
    return false;
  }

  pageEditorService.component.set(component);
  return true;
}


