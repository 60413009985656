import {ChangeDetectionStrategy, Component, computed, HostBinding, input} from "@angular/core";
import {ComponentConfig} from "../../../project";
import {ContainerColumnConfig} from "../select-component-dialog/types";
import {PageEditorComponentContainerColumnComponent} from "./page-editor-component-container-column.component";

@Component({
    selector: 'page-editor-component-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageEditorComponentContainerColumnComponent,
  ],
  template: `
    <div class="component-container-layout" [style.grid-template-columns]="gridTemplateColumns()">
      @for (column of columns(); track column) {
        <page-editor-component-container-column [container]="component()" [column]="column" />
      }
    </div>
  `
})
export class PageEditorComponentContainerComponent {
  @HostBinding('class.page-editor-component-container') cssClass = true;
  @HostBinding('class.component-container') containerClass = true;
  component = input.required<ComponentConfig>();
  config = computed(() => this.component().config);
  columns = computed<Array<ContainerColumnConfig>>(() => this.config()['columns']);
  gridTemplateColumns = computed<string>(() => this.columns().map(column => column.gridColumnWidth).join(' '));
}
