import {
  ChangeDetectionStrategy,
  Component, computed,
  HostBinding, inject,
  input, OnInit,
  output,
  signal,
  ViewChild
} from "@angular/core";
import {MapComponent} from "./map.component";
import {MatButton} from "@angular/material/button";
import {MatToolbar} from "@angular/material/toolbar";
import {RichMapCommentFormComponent} from "./rich-map-comment-form.component";
import {ComponentConfig, componentConfigFactory} from "../../project";
import {MapTagService} from "../services/map-tag.servie";
import {AdminService} from "../../../admin/admin.service";
import {DeployService} from "../../../deploy/deploy.service";
import {PageEditorService} from "../../page-editor/services/page-editor.service";
import {MapEntry, MapMarkerConfig, MapTag} from "../types";
import {MapEntryService} from "../services/map-entry.service";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'rich-map-container',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MapComponent,
    MatButton,
    MatToolbar,
    RichMapCommentFormComponent,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
  ],
  template: `
    <mat-toolbar>
      @if (isEditorMode()) {
        <button (click)="prepareDefineCenter()" mat-button>Startpunkt auswählen</button>
      } @else {

        @if(isFilterButtonVisible()) {
          <button (click)="toggleFilterOverlay()" mat-button>Filter</button>
        }

        @if (isMarkerButtonVisible()) {
          <button (click)="toggleAddMarkerState()" mat-button>
            @if (addMarkerState()) {
              Position auf der Karte klicken (abbrechen)
            } @else {
              Marker hinzufügen
            }
          </button>
        }
      }
    </mat-toolbar>
    <div class="rich-map-map-container">
      <pa-map #map (mapClick)="mapClicked($event)" [center]="center()"/>
      <div class="rich-map-map-overlay flex-row" [class.hide]="formOverlayHidden()">
        <rich-map-comment-form
          [clientId]="clientId()"
          [projectId]="projectId()"
          [isUserActivated]="isUserActivated()"
          [tags]="tags()"
          [latLng]="addMarkerLatLng()"
          (saved)="entrySaved($event)"
          (canceled)="entryCanceled()"
        />
      </div>
      <div class="rich-map-map-overlay flex-row" [class.hide]="filterOverlayHidden()">
        <div>

          <mat-form-field>
            <mat-label>Tags</mat-label>
            <mat-select [formControl]="tagsControl" multiple>
              @for (tag of tags(); track tag) {
                <mat-option [value]="tag.id">{{tag.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button (click)="toggleFilterOverlay()" mat-raised-button>Filter anwenden</button>
        </div>
      </div>
    </div>
  `,
})
export class RichMapContainerComponent implements OnInit {
  @HostBinding('class.rich-map') classRichMap = true;
  isDeployView = input(false);
  isEditorMode = input(false);
  component = input<ComponentConfig>(componentConfigFactory());
  onSelectCenter = output<{latlng: L.LatLng, zoom: number}>();
  @ViewChild(MapComponent)
  map: MapComponent | undefined;
  private tagService = inject(MapTagService);
  private adminService = inject(AdminService);
  private deployService = inject(DeployService);
  private pageEditorService = inject(PageEditorService);
  private mapEntryService = inject(MapEntryService);
  private nav = inject(NavigationService);
  clientId = computed(() => this.isDeployView() ? this.deployService.clientId() : this.adminService.clientId());
  projectId = computed(() => this.isDeployView() ? this.deployService.projectId() : this.pageEditorService.projectId());
  isUserActivated = computed(() => this.isDeployView()
    ? this.deployService.isUserActivated()
    : this.adminService.isUserActivated());

  private tagIds = computed<Array<string>>(() => this.component()?.config['tag_ids'] || []);
  allTags = this.tagService.items;
  tags = computed(() => this.allTags().filter(tag => this.tagIds().includes(tag.id)));
  activeTags= signal<Array<MapTag>>([]);
  entries = this.mapEntryService.items;
  tagsControl = new FormControl<Array<string>>([]);

  addMarkerState = signal(false);
  addMarkerLatLng = signal({lat: 0, lng: 0} as L.LatLng);
  flagSelectCenter = signal(false);
  formOverlayHidden = signal(true);
  filterOverlayHidden = signal(true);
  isMarkerButtonVisible = computed(() => this.formOverlayHidden() && this.filterOverlayHidden());
  isFilterButtonVisible = computed(() => this.formOverlayHidden() && !this.addMarkerState());

  center = computed(() => {
    const config = this.component().config;

    if ( Object.hasOwn(config, 'center')) {
      return config['center'];
    }

    // default, if not configured
    return {
      // latlng: [52.5200, 13.4050], // Berlin
      // latlng: [48.73336, 9.31976], // Esslingen
      latlng: {lat: 48.73336, lng: 9.31976}, // Esslingen
      zoom: 12
    };
  });

  toggleAddMarkerState() {
    this.addMarkerState.update(value => !value);
  }

  mapClicked(event: {latlng: L.LatLng, zoom: number}) {
    if(this.addMarkerState()) {
      this.addMarkerLatLng.set(event.latlng);
      this.openEntryForm();
    }

    if(this.flagSelectCenter()) {
      this.flagSelectCenter.set(false);
      this.onSelectCenter.emit(event);
    }
  }

  prepareDefineCenter() {
    this.flagSelectCenter.set(true);
  }

  ngOnInit() {
    this.tagService.filterSetClientId(this.clientId());
    this.tagService.filterSetProjectId(this.projectId());
    this.tagService.loadList().then(this.afterLoadTags);
    this.tagsControl.valueChanges.subscribe(this.updateFilterTagIds);
  }

  private updateFilterTagIds = (tagIds: Array<string> | null) => {
    if(tagIds === null) {
      tagIds = [];
    }
    this.mapEntryService.filterSetTagIds(tagIds);
    this.reloadMarkers();
  }

  afterLoadTags = () => {
    this.activeTags.set(this.tags());
    this.tagsControl.setValue(this.tagIds(), {emitEvent: false});
    this.reloadMarkers()
  }

  reloadMarkers = () => {
    this.mapEntryService.filterSetSearch('');
    this.mapEntryService.filterSetModerationStatus('approved');
    this.mapEntryService.filterSetClientId(this.clientId());
    this.mapEntryService.filterSetProjectId(this.projectId());
    this.mapEntryService.filterSetTagIds(this.tagsControl.value as Array<string>);
    this.mapEntryService.loadList().then(this.afterLoadMapEntries);
  }


  afterLoadMapEntries = () => {
    this.removeMarkers();

    // add map markers
    this.entries().forEach(entry => {
      const entryConfig = JSON.parse(entry.config);

      let symbol = 'i';
      let color = '#000000';

      // override symbol and color by tag, if it exists
      if(entry.tag_ids.length > 0) {
        const tagId = entry.tag_ids[0];
        const tag = this.allTags().find(tag => tag.id === tagId);
        if(tag) {
          const tagConfig = JSON.parse(tag.config);
          if(tagConfig.color) {
            color = tagConfig.color;
          }
          if(tagConfig.symbol) {
            symbol = tagConfig.symbol;
          }
        }
      }

      const markerConfig: MapMarkerConfig = {
        latlng: entryConfig.latlng,
        color,
        symbol,
        clickFn: () => this.nav.navigate(this.mapEntryDetailLink(entry))
      }

      this.map!.addCustomMarker(markerConfig);
    });
  }

  private mapEntryDetailLink = (entry: MapEntry) => {

    if(this.isDeployView()) {
      let pagePath = this.deployService.pagePath();
      pagePath = pagePath.length > 0 ? `${pagePath}/` : '';
      return `${pagePath}map-entry-display/${entry.id}`;
    }

    const projectId = this.pageEditorService.project().id;
    const pageId = this.pageEditorService.page().id;
    return `admin/component-editor/${projectId}/${pageId}/map-entry-display/${entry.id}`;
  }

  entryCanceled() {
    this.closeEntryForm();
  }

  entrySaved(entry: MapEntry) {
    this.closeEntryForm();
    this.reloadMarkers();
  }

  closeEntryForm() {
    this.addMarkerState.set(false);
    this.formOverlayHidden.set(true);
  }

  openEntryForm() {
    this.formOverlayHidden.set(false);
  }

  removeMarkers() {
    this.map!.clearMarkers();
  }

  toggleFilterOverlay() {
    if(this.filterOverlayHidden()) {
      this.openFilterOverlay();
    } else {
      this.closeFilterOverlay()
    }
  }
  openFilterOverlay() {
    this.filterOverlayHidden.set(false);
  }
  closeFilterOverlay() {
    this.filterOverlayHidden.set(true);
  }
}
