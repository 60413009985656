import {ChangeDetectionStrategy, Component, HostBinding, inject, input, OnInit, output, signal} from "@angular/core";
import {ContainerColumnConfig} from "../select-component-dialog/types";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'component-container-column-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatLabel,
    MatInput,
    MatButtonModule,
  ],
  template: `
<div class="field-container flex">
  <mat-form-field appearance="outline">
    <mat-label>Breite</mat-label>
    <input matInput [formControl]="columnWidthControl"/>
    <mat-error>Name benötigt!</mat-error>
  </mat-form-field>
  <button (click)="save()" mat-button>Übernehmen</button>
  <button (click)="delete()" mat-button>Entfernen</button>
</div>
  `
})
export class PageEditorComponentContainerColumnFormComponent implements OnInit {
  @HostBinding('class.component-container-column-form') cssClass = true;
  private note = inject(NotificationService);
  column = input.required<ContainerColumnConfig>();
  updated = output<ContainerColumnConfig>();
  deleted = output<ContainerColumnConfig>();
  columnWidthControl = new FormControl('');

  ngOnInit() {
    this.columnWidthControl.setValue(this.column().gridColumnWidth, {emitEvent: false});
  }

  save() {
    console.log('save');
    const gridColumnWidth = this.columnWidthControl.value;
    console.log('save', gridColumnWidth);
    const column = this.column();
    const updatedConfig = {
      ...column,
      gridColumnWidth
    } as ContainerColumnConfig;
    this.updated.emit(updatedConfig);
  }

  delete() {
    this.note.confirm('Wirklich entfernen?', () => {
      this.deleted.emit(this.column());
    })
  }
}
