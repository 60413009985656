import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal
} from "@angular/core";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {PinboardEntryCardComponent} from "./pinboard-entry-card.component";
import {ComponentConfig, componentConfigFactory} from "../../project";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {I18nService} from "../../i18n";
import {PinboardEntrySavedEvent} from "../events/pinboard-entry-saved.event";
import {Subscription} from "rxjs";

@Component({
  selector: 'pinboard',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PinboardEntryCardComponent,
    MatCard,
    MatCardContent,
    MatCardHeader,
  ],
  template: `
    <mat-card [style.background-color]="bgPinboardColor()">
      <mat-card-header>
        <div class="pinboard-title">
          {{title()}}
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="entry-container">
          @for (item of items(); track item) {
            <pinboard-entry-card [entry]="item"
                                 [component]="component()"
                                 [isDeployView]="isDeployView()"/>
          }
        </div>
      </mat-card-content>
    </mat-card>
  `
})
export class PinboardComponent implements OnInit, OnDestroy {
  @HostBinding('class.pinboard') cssCLass = true;
  isDeployView = input(false);
  private savedEvent = inject(PinboardEntrySavedEvent);
  private savedEventSubscription: Subscription | null = null;
  private service = inject(PinboardEntryService);
  private i18n = inject(I18nService);
  component = input<ComponentConfig>(componentConfigFactory());
  clientId = input<string>('');
  projectId = input<string>('');

  title = computed(() => {
    const fallback = 'Pinwand';
    const config = this.component()['config'] || {};

    if(Object.hasOwn(config, 'title')) {
      const value = this.i18n.extract(this.component()['config']['title'], fallback)();
      return value.length > 0 ? value : fallback;
    }

    return fallback;
  });

  bgPinboardColor = signal<string>('#FFFFFF');
  items = this.service.items;
  ngOnInit() {
    const config = this.component().config || {};
    const tagIds = Object.hasOwn(config, 'tag_ids') ? config['tag_ids'] : [];

    this.service.filterSetSearch('');
    this.service.filterSetProjectId(this.projectId());
    this.service.filterSetClientId(this.clientId());
    this.service.filterSetModerationStatus('public');
    this.service.filterSetTagIds(tagIds);
    this.service.loadList();

    if(Object.hasOwn(config, 'bg_color_pinboard')) {
      this.bgPinboardColor.set(config['bg_color_pinboard']);
    }

    // reload, if a save event was fired
    this.savedEventSubscription  = this.savedEvent.subscribe(item => {
      this.service.loadList();
    });
  }
  ngOnDestroy() {
    this.savedEventSubscription?.unsubscribe();
  }
}
