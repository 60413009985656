import {ChangeDetectionStrategy, Component, computed, input} from "@angular/core";
import {MapEntry, mapEntryFactory} from "../types";
import {MarkdownComponent} from "ngx-markdown";
import {MatDivider} from "@angular/material/divider";

@Component({
  selector: 'map-entry-display',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MarkdownComponent,
    MatDivider
  ],
  template: `
    <div class="pinboard-entry-title">{{ title() }}</div>
    <div class="flex-row gap">
      <div class="flex">
        {{ author() }}
      </div>
      <div>{{ created() }}</div>
    </div>
    <mat-divider/>
    <div class="pinboard-entry-content">
      <markdown [data]="content()"/>
    </div>`,
})
export class MapEntryDisplayComponent {
  entry = input<MapEntry>(mapEntryFactory());
  title = computed(() => this.entry().title);
  author = computed(() => this.entry().author_name);
  created = computed(() => this.entry().created);
  content = computed(() => this.entry().short_description);
}
