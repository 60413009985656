import {ChangeDetectionStrategy, Component, HostBinding, inject, input} from "@angular/core";
import {RichMapContainerComponent} from "../../map/components/rich-map-container.component";
import {PageEditorService} from "../services/page-editor.service";
import {PinboardComponent} from "../../pinboard/components/pinboard.component";
import {HeroTeaserComponent} from "./hero-teaser/hero-teaser.component";
import {ComponentConfig} from "../../project";
import {ArticleComponent} from "./article/article.component";
import {PinboardEntryFormComponent} from "../../pinboard/components/pinboard-entry-form.component";
import {AdminService} from "../../../admin/admin.service";
import {PageEditorComponentContainerComponent} from "./component-container/page-edtor-component-container.component";
import {PageEditorComponentMenuComponent} from "./page-editor-component-menu.component";

@Component({
  selector: 'page-editor-component-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RichMapContainerComponent,
    PinboardComponent,
    HeroTeaserComponent,
    ArticleComponent,
    PinboardEntryFormComponent,
    PageEditorComponentContainerComponent,
    PageEditorComponentMenuComponent,
  ],
  template: `
    @for (component of components(); track component) {
      @switch (component.type) {
        @case ('hero-teaser') {
          <div class="page-editor-component-wrapper component-wrapper">
            <hero-teaser [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('rich-map') {
          <div class="page-editor-component-wrapper component-wrapper">
            <rich-map-container [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('map') {
          <div class="page-editor-component-wrapper">
            <rich-map-container [component]="component"/>
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('pinboard') {
          <div class="page-editor-component-wrapper component-wrapper">
            <pinboard [component]="component"
                      [clientId]="clientId()"
                      [projectId]="projectId()" />
            <pinboard-entry-form [pinboardComponent]="component"
                                 [clientId]="clientId()"
                                 [projectId]="projectId()" />
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('article') {
          <div class="page-editor-component-wrapper component-wrapper">
            <article-component [component]="component" />
            <page-editor-component-menu [component]="component"/>
          </div>
        }
        @case ('container') {
          <div class="page-editor-component-wrapper component-wrapper">
            <page-editor-component-container [component]="component" />
            <page-editor-component-menu [component]="component"/>
          </div>
        }
      }
    }
  `
})
export class PageEditorComponentListComponent {
  @HostBinding('class.page-editor-component-list') cssClass = true;
  private service = inject(PageEditorService);
  private adminService = inject(AdminService);
  clientId = this.adminService.clientId;
  projectId = this.service.projectId;
  config = this.service.config;
  components = input.required<Array<ComponentConfig>>();
}
