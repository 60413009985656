import {ChangeDetectionStrategy, Component, computed, input} from "@angular/core";
import {mapEntryCommentFactory} from "../types";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatDivider} from "@angular/material/divider";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'map-entry-comment-display',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatDivider,
    MarkdownComponent
  ],
  template: `
    <mat-card>
      <mat-card-header>
        Von: {{author()}}<br />
        {{created()}}
      </mat-card-header>
      <mat-divider />
      <mat-card-content>
        <markdown [data]="content()" />
      </mat-card-content>
    </mat-card>
  `
})
export class MapEntryCommentDisplayComponent {
  comment = input(mapEntryCommentFactory());
  author = computed(() => this.comment().author_name);
  created = computed(() => this.comment().created);
  content = computed(() => this.comment().content);
}
