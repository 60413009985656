import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {PinboardTagService} from "../services/pinboard-tag.service";
import {pinboardEntryFactory} from "../types";
import {PinboardEntryService} from "../services/pinboard-entry.service";
import {AdminService} from "../../../admin/admin.service";

export const canEditPinboardEntry: CanActivateFn =  async (route, state) => {
  const entryService = inject(PinboardEntryService);
  const tagService = inject(PinboardTagService);
  const adminService = inject(AdminService);
  const clientId = adminService.clientId();
  const entryId = `${route.paramMap.get('id')}`;

  entryService.filterSetClientId(clientId);
  tagService.filterSetClientId(clientId);

  try {
    const entry = await entryService.fetchItem(entryId);
    if (!entry) {
      entryService.currentItem.set(pinboardEntryFactory());
      return true;
    }
    tagService.filterSetProjectId(entry.project_id);
    await tagService.loadList();
    entryService.currentItem.set(entry);
  } catch (e) {
    entryService.currentItem.set(pinboardEntryFactory());
  }
  return true;
}
